import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { Navbar, Container, Stack, Form, Button, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../image/Helix-logo.png";
import { FcGoogle } from "react-icons/fc";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import ToastMessage from "./ToastMessage";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
// import { getBaseUrl } from "../base";
// var AWS = require("aws-sdk");

const Login = () => {
	const {
		Pool,
		authenticate,
		loggedInUser,
		loginStatus,
		getSession,
		setLoginStatus,
		setLoggedInUser,
	} = useContext(AccountContext);

	// function parseJwt(token) {
	//   var base64Url = token.split(".")[1];
	//   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	//   var jsonPayload = decodeURIComponent(
	//     window
	//       .atob(base64)
	//       .split("")
	//       .map(function (c) {
	//         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	//       })
	//       .join("")
	//   );

	//   return JSON.parse(jsonPayload);
	// }

	// console.log(
	//   process.env.REACT_APP_SERVER_IP,
	//   process.env.REACT_APP_CLIENT_PORT
	// );

	// const location = useLocation();
	// let token = location.hash.split("&");
	// let accessToken = "";
	// let idToken = "";
	// token.forEach((el) => {
	//   if (el.includes("access_token=")) {
	//     accessToken = el.split("=")[1];
	//   }

	//   if (el.includes("id_token=")) {
	//     idToken = el.split("=")[1];
	//   }
	// });

	// function signinCallback(id_Token) {
	//   // Add the Google access token to the Amazon Cognito credentials login map.
	//   // Initialize the Amazon Cognito credentials provider
	//   AWS.config.region = "us-east-2"; // Region
	//   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	//     IdentityPoolId: process.env.REACT_APP_IDENTITYID,
	//     Logins: {
	//       "accounts.google.com": id_Token,
	//     },
	//   });

	//   // Obtain AWS credentials
	//   AWS.config.credentials.get(function (d) {
	//     // Access AWS resources here.
	//     console.log(d);
	//   });
	// }

	// useEffect(() => {
	//   if (!location.hash) return;
	//   console.log("accessToken:", parseJwt(accessToken));
	//   console.log("idToken:", parseJwt(idToken));
	//   let id_Token = parseJwt(idToken);
	//   // id_Token.identities[0].issuer = "Google";
	//   // console.log(id_Token);
	//   signinCallback(idToken);
	// }, [location]);

	const navigate = useNavigate();
	const location = useLocation();
	const [sessionExpired, setSessionExpired] = useState(location.state? location.state.sessionExpired : false);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	useEffect(() => {
		const getSessionData = async () => {
			const { userInfo } = await getSession();
			setLoggedInUser(userInfo);
			setLoginStatus(true);
		};
		getSessionData();
	}, []);

	if (loginStatus === true && loggedInUser.group === "Admin") {
		navigate("/admin");
	} else if (loginStatus === true && loggedInUser.group === "User") {
		navigate(`/user/dashboard/${loggedInUser.UID}`);
	}

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passShow, setPassShow] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const togglePassword = () => {
		setPassShow(!passShow);
	};

	const onLogin = (event) => {
		event.preventDefault();

		if (email === "" || password === "") {
			setMessage("Email or password missing!");
			setStatus(false);
			setToast(true);
			return;
		}

		authenticate(email, password)
			.then((data) => {
				if (data.role === "Admin") {
					navigate("/admin");
				} else if (data.role === "User") {
					navigate(`/user/dashboard/${data.UID}`);
				}
			})
			.catch((err) => {
				setErrMsg(err.message);
			});
	};

	useEffect(() => {
		if (sessionExpired) {
			setMessage("Session has expired. Please log in again.");
			setStatus(false);
			setToast(true);
			setSessionExpired(false);
			navigate(location.pathname, { state: { sessionExpired: false }, replace: true})
		}
	}, [sessionExpired]);

	return (
		<div>
			<Navbar fixed="top" className="navbar">
				<Container>
					<Navbar.Brand href="/">
						<img
							alt=""
							src={logo}
							width="auto"
							height="40"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
				</Container>
			</Navbar>
			<ToastMessage
				toast={toast}
				onClose={() => setToast(!toast)}
				message={message}
				status={status}
			/>
			<br />
			<div className="login-div">
				<Stack gap={3}>
					<h1 className="text-muted text-center">
						Log in to{" "}
						<span style={{ color: "#163172" }}>Helix</span>
					</h1>
					<Form onSubmit={onLogin}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>

						<Form.Group
							className="mb-3"
							controlId="formBasicPassword"
						>
							<div style={{ display: "flex" }}>
								<Form.Control
									type={passShow ? "text" : "password"}
									placeholder="Password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								{password.length ? (
									<Button
										variant="outline-light"
										style={{ color: "#163172" }}
										onClick={togglePassword}
									>
										{passShow === false ? (
											<BsFillEyeFill />
										) : (
											<BsFillEyeSlashFill />
										)}
									</Button>
								) : (
									<></>
								)}
							</div>

							{errMsg.length > 0 ? (
								<Form.Text
									// className="text-muted"
									style={{
										color: "#ff0000",
										fontSize: "10px",
									}}
								>
									{errMsg}
								</Form.Text>
							) : (
								<></>
							)}
						</Form.Group>
						<Button
							className="mb-3"
							variant="light"
							style={{ color: "#163172", width: "100%" }}
							type="submit"
						>
							Login
						</Button>
					</Form>
					{/* <p style={{ color: "#163172", textAlign: "center" }}>or</p>
          <Button
            className="mb-3"
            variant="light"
            style={{ color: "#163172", width: "100%" }}
            type="submit"
            onClick={() => {
              // window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=token&scope=email+openid+profile&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/`;
              // onclick="window.location.href='/oauth2/authorize?identity_provider=Google&amp;redirect_uri=http://localhost:3000/&amp;response_type=TOKEN&amp;client_id=7pf0nt0sbenme8giv8e15djvmt&amp;scope=email openid profile'"
              window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/&response_type=TOKEN&client_id=${process.env.REACT_APP_CLIENTID}&scope=email+openid+profile`;
            }}
          >
            <FcGoogle />
            &nbsp;&nbsp; Continue with Google
          </Button> */}
					<p style={{ color: "#163172", textAlign: "center" }}>
						Forgot your password?{" "}
						<Link to="/forgotpassword">click here</Link>
					</p>

					<p style={{ color: "#163172", textAlign: "center" }}>
						Don’t have an account?{" "}
						<Link to="/register">Register</Link>
					</p>
				</Stack>
			</div>
		</div>
	);
};

export default Login;
