import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Stack, Form } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import Navigate from "../../Navigate";
import {
	CognitoUserPool,
	CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { UserContext } from "../../Contexts/UserContext";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const EditProfile = () => {
	const { loginStatus, loggedInUser, getSession, authenticate, Pool } =
		useContext(AccountContext);

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);

	const { updateUser } = useContext(UserContext);

	const [newFirstName, setNewFirstName] = useState("");
	const [newLastName, setNewLastName] = useState("");
	const [newEmail_ID, setNewEmail_ID] = useState("");
	const [pass, setPass] = useState("");
	const [passShow, setPassShow] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [errMsg, setErrMsg] = useState("");

	const navigate = useNavigate();

	const togglePassword = () => {
		setPassShow(!passShow);
	};

	useEffect(() => {
		if (
			newFirstName !== "" &&
			newLastName !== "" &&
			newEmail_ID !== "" &&
			pass !== ""
		) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	});

	const setCognitoUserAttribute = (attributeKey, attributeValue) => {
		let data = {
			Name: attributeKey,
			Value: attributeValue,
		};

		return new CognitoUserAttribute(data);
	};

	const onUpdate = (event) => {
		event.preventDefault();

		if (newFirstName === "" || newLastName === "" || newEmail_ID === "") {
			setMessage("No change detected!");
			setStatus(false);
			setToast(true);
			return;
		}

		if (
			newFirstName === loggedInUser.first_name &&
			newLastName === loggedInUser.last_name &&
			newEmail_ID === loggedInUser.email_ID
		) {
			setMessage("No change detected!");
			setStatus(false);
			setToast(true);
			return;
		}

		let attributeList = [];

		attributeList.push(setCognitoUserAttribute("email", newEmail_ID));
		attributeList.push(setCognitoUserAttribute("name", newFirstName));
		attributeList.push(setCognitoUserAttribute("family_name", newLastName));

		getSession().then(({ user, userInfo }) => {
			authenticate(userInfo.email_ID, pass).then(() => {
				user.updateAttributes(attributeList, (err, results) => {
					if (err) {
						setErrMsg(err.message);
						return;
					} else {
						authenticate(newEmail_ID, pass).then((data) => {
							let updateObj = {};
							updateObj["first_name"] = data.first_name;
							updateObj["last_name"] = data.last_name;
							updateObj["email_ID"] = data.email_ID;
							let updateToDB = updateUser(data.UID, updateObj);
							if (updateToDB) {
								setMessage(
									"Profile details updated successfully!"
								);
								setStatus(true);
								setToast(true);
								navigate(`/user/profile/${data.UID}`);
							}
						});
					}
				});
			});
		});
	};

	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
					<ToastMessage
						toast={toast}
						onClose={() => setToast(!toast)}
						message={message}
						status={status}
					/>
					<br />
					<br />
					<div className="edit-card">
						<Form onSubmit={onUpdate}>
							<Card>
								<Card.Header className="text-center">
									Edit Your Profile Details
								</Card.Header>
								<Card.Body>
									<Stack gap={3} className="profile-details">
										<Stack direction="horizontal">
											<Form.Label
												column
												sm={3}
												className="userAssmt-label"
											>
												<b>First Name:</b>
											</Form.Label>
											<Form.Control
												type="text"
												placeholder={
													loggedInUser.first_name
												}
												// defaultValue={loggedInUser.first_name}
												onChange={(e) => {
													setNewFirstName(
														e.target.value
													);
												}}
											/>
										</Stack>
										<Stack direction="horizontal">
											<Form.Label
												column
												sm={3}
												className="userAssmt-label"
											>
												<b>Last Name:</b>
											</Form.Label>
											<Form.Control
												type="text"
												placeholder={
													loggedInUser.last_name
												}
												// defaultValue={loggedInUser.last_name}
												onChange={(e) => {
													setNewLastName(
														e.target.value
													);
												}}
											/>
										</Stack>
										<Stack direction="horizontal">
											<Form.Label
												column
												sm={3}
												className="userAssmt-label"
											>
												<b>Email ID:</b>
											</Form.Label>
											<Form.Control
												type="text"
												placeholder={
													loggedInUser.email_ID
												}
												// defaultValue={loggedInUser.email_ID}
												onChange={(e) => {
													setNewEmail_ID(
														e.target.value
													);
												}}
											/>
										</Stack>
										<Stack direction="horizontal">
											<Form.Label
												column
												sm={3}
												className="userAssmt-label"
											>
												<b>Group:</b>
											</Form.Label>
											<label>{loggedInUser.group}</label>
										</Stack>
										<Stack direction="horizontal">
											<Form.Label
												column
												sm={3}
												className="userAssmt-label"
											>
												<b>Password:</b>
											</Form.Label>
											<Form.Control
												type={
													passShow
														? "text"
														: "password"
												}
												placeholder="Enter your password to save changes"
												onChange={(e) => {
													setPass(e.target.value);
												}}
											/>
											{pass.length ? (
												<Button
													variant="outline-light"
													style={{ color: "#163172" }}
													onClick={togglePassword}
												>
													{passShow === false ? (
														<BsFillEyeFill />
													) : (
														<BsFillEyeSlashFill />
													)}
												</Button>
											) : (
												<></>
											)}
										</Stack>
									</Stack>
									{errMsg.length > 0 ? (
										<>
											<br />
											<Stack direction="horizontal">
												<Form.Text
													className="mx-auto"
													// className="text-muted"
													style={{
														color: "#ff0000",
														fontSize: "10px",
													}}
												>
													{errMsg}
												</Form.Text>
											</Stack>
										</>
									) : (
										<></>
									)}
								</Card.Body>
								<Card.Footer className="d-flex justify-content-between">
									<Button
										variant="outline-secondary"
										onClick={() => {
											setNewFirstName("");
											setNewLastName("");
											setNewEmail_ID("");
											navigate(
												`/user/profile/${loggedInUser.UID}`
											);
										}}
									>
										Cancel
									</Button>{" "}
									<Button
										type="submit"
										variant="outline-success"
										disabled={btnDisabled}
									>
										Save
									</Button>
								</Card.Footer>
							</Card>
						</Form>
					</div>
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default EditProfile;
