import React, { useEffect, useRef, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { AccountContext } from "../../Contexts/AccountContext";

const UserResponseTable = (props) => {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();
  const { assmtType } = useContext(AssmtContext);
  const { apiCall } = useContext(AccountContext);
  const { exportSingleResToCSV } = useContext(AssmtContext);

  const setCSVfilename = props.setCSVfilename;
  const setHeaderName = props.setHeaderName;
  const setUserRes = props.setUserRes;
  const handleShow = props.handleShow;
  const setBodyName = props.setBodyName;

  async function getData(userData) {
    const { data } = await apiCall(`admin/getAssmtType/${userData.UAID}`);
    localStorage.setItem('secAssmtType', data["type"]);
    if(data["type"] === "individual") navigate(`/admin/viewUserResponse/${data["AID"]}/${userData.UAID}`);
    else if(data["type"] === "group") navigate(`/admin/viewGrpAssmtUserResponse/${props.assmtID}/${userData.UAID}`);
  }

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.userAssmtDetails,
      columns: [
        { className: "dt-body-left", title: "First Name", data: "first_name" },
        { className: "dt-body-left", title: "Last Name", data: "last_name" },
        { className: "dt-body-left", title: "Email ID", data: "email_ID" },
        {
          className: "dt-body-left",
          title: "Position",
          data: "positionOption",
        },
        { className: "dt-body-left", title: "Title", data: "positionTitle" },
        {
          className: "dt-body-left",
          title: "Reference Date",
          data: "referenceDate",
        },
        {
          className: "dt-body-left",
          title: "Start Time",
          data: "start_Tstamp",
        },
        { className: "dt-body-left", title: "End Time", data: "end_Tstamp" },
        {
          className: "dt[-head|-body]-center",
          title: "Response",
          defaultContent: `<Button class="view-btn btn btn-outline-secondary btn-sm">
              View
            </Button>`,
        },
        {
          className: "dt[-head|-body]-center",
          title: "Analysis",
          defaultContent: `<Button class="analysis-view-btn btn btn-outline-secondary btn-sm">
              View
            </Button>`,
        },
        {
          className: "dt[-head|-body]-center",
          title: "Export",
          defaultContent: `<Button class="csvbtn btn btn-outline-secondary btn-sm">
                CSV
              </Button>`,
        },
      ],
      destroy: true,
    });
    $("#example tbody").on("click", ".view-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        getData(data);
      }
    });
    $("#example tbody").on("click", ".analysis-view-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        navigate(`/analyze/bysection/${props.assmtID}/${data.UID}`, { state: { UAID: data.UAID } });
      }
    });
    $("#example tbody").on("click", ".csvbtn", async function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        let exportData = await exportSingleResToCSV(
          data.UID,
          data.UAID,
          data.AID
        );
        setUserRes(exportData);
        setBodyName(
          `${data.first_name} ${data.last_name}'s response is generated as .csv file and ready to download!`
        );
        setCSVfilename(
			`${data.UID} ${data.UAID} ${data.first_name} ${
				data.last_name
			} ${data.end_Tstamp}.csv`
		);
        handleShow();
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.userAssmtDetails]);

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left"
        width="100%"
        id="example"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Position</th>
            <th>Title</th>
            <th>Reference Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Response</th>
            <th>Analysis</th>
            <th>Export</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default UserResponseTable;
