import React, { createContext, useState, useEffect } from "react";
import {
	CognitoUserPool,
	AuthenticationDetails,
	CognitoUser,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { getBaseUrl } from "../../base";

/**
 * @callback ApiCallFunc
 * Makes an asynchronous API call using Axios.
 * @param {string} url - The URL for the API endpoint.
 * @param {string} [method="GET"] - The HTTP method for the request (default is "GET").
 * @param {Object} [data={}] - The data to be sent with the request (default is an empty object).
 * @param {Object} [headers={}] - The headers to be included in the request (default is an empty object).
 * @param {Object} [params={}] - The query parameters to be included in the request (default is an empty object).
 * @returns {Promise} A Promise that resolves to the response data from the API call.
 */

export const AccountContext = createContext();

const AccountContextProvider = ({ children }) => {
	const [loggedInUser, setLoggedInUser] = useState({});
	const [loginStatus, setLoginStatus] = useState(false);
	const navigate = useNavigate();
	const baseURL = `${getBaseUrl()}/`;
	const axios = Axios.create({
		baseURL,
	});

	/**
	 * @type {ApiCallFunc}
	 */
	const apiCall = async (
		url,
		method = "GET",
		data = {},
		headers = {},
		params = {}
	) => {
		const token = await loggedInUser.jwtToken;
		if (token) {
			try {
				return await axios({
					method,
					url,
					data,
					params,
					headers: {
						...headers,
						Authorization: `Bearer ${token}`,
					},
				});
			} catch (error) {
				console.log("CLIENT ERROR : ",error);
				if (error.response && error.response.status === 401) {
					if (error.response.data === "Session has expired. Please log in again." || error.response.data.message === "Session has expired. Please log in again.") {
						console.error("Session expired. Please log in again.", error);
						logout();
						navigate("/", { state: { sessionExpired: true } });
					} else {
						console.error("Unauthorized access.", error);
					}
				} else {
					console.error("An error occurred:", error);
				}
				return error;
			}
		} 
	};

	const poolData = {
		UserPoolId: process.env.REACT_APP_USERPOOLID,
		ClientId: process.env.REACT_APP_CLIENTID,
	};

	const Pool = new CognitoUserPool(poolData);

	const authenticate = async (Username, Password) =>
		await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool });
			const authDetails = new AuthenticationDetails({
				Username,
				Password,
			});

			user.authenticateUser(authDetails, {
				onSuccess: (data) => {
					let sessionObj = data.idToken.payload;
					let jwtToken = data.accessToken.jwtToken;
					const { email_verified } = Axios.get(
						`${getBaseUrl()}/admin/getUserDetails/${sessionObj.sub}`,
						{
							headers: {
								Authorization: `Bearer ${jwtToken}`,
							},
						}
					);
					if (email_verified !== sessionObj.email_verified) {
						Axios.put(
							`${getBaseUrl()}/user/updateEmail_verified/${sessionObj.sub}`,
							{ email_verified: sessionObj.email_verified },
							{
								headers: {
									Authorization: `Bearer ${jwtToken}`,
								},
							}
						);
					}
					if (jwtToken !== undefined) {
						let userInfo = {};
						userInfo["UID"] = sessionObj.sub;
						userInfo["first_name"] = sessionObj.name;
						userInfo["last_name"] = sessionObj.family_name;
						userInfo["email_ID"] = sessionObj.email;
						userInfo["email_verified"] = sessionObj.email_verified;
						userInfo["jwtToken"] = jwtToken;
						userInfo["group"] = sessionObj["cognito:groups"][0];
						setLoggedInUser(userInfo);
						setLoginStatus(true);
						resolve(userInfo);
					}
				},

				onFailure: (err) => {
					//   console.error("onFailure:", err);
					reject(err);
				},

				newPasswordRequired: (data) => {
					//   console.log("newPasswordRequired:", data);
					resolve(data);
				},
			});
		});

	const getSession = async () =>
		await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			if (user) {
				user.getSession((err, session) => {
					if (err) {
						reject();
					} else {
						let sessionObj = session.idToken.payload;
						let jwtToken = session.accessToken.jwtToken;
						if (jwtToken !== undefined) {
							let userInfo = {};
							userInfo["UID"] = sessionObj.sub;
							userInfo["first_name"] = sessionObj.name;
							userInfo["last_name"] = sessionObj.family_name;
							userInfo["email_ID"] = sessionObj.email;
							userInfo["email_verified"] = sessionObj.email_verified;
							userInfo["jwtToken"] = jwtToken;
							userInfo["group"] = sessionObj["cognito:groups"][0];
							resolve({ user, userInfo });
						}
					}
				});
			} else {
				reject();
			}
		});

	useEffect(() => {
		const getSessionData = async () => {
			const { userInfo } = await getSession();
			setLoggedInUser(userInfo);
			setLoginStatus(true);
		};
		getSessionData();
	}, []);

	const logout = () => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
			setLoggedInUser({});
			setLoginStatus(false);
		}
	};

	// console.log(loggedInUser);

	return (
		<AccountContext.Provider
			value={{
				Pool,
				authenticate,
				getSession,
				loginStatus,
				loggedInUser,
				setLoginStatus,
				setLoggedInUser,
				logout,
				apiCall,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};

export default AccountContextProvider;
