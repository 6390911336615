import React, { useContext, useEffect, useState } from "react";
import Navigate from "../../Navigate";
import { AccountContext } from "../../Contexts/AccountContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import AccessDenied from "../../AccessDenied";
import ToastMessage from "../../ToastMessage";
import { getBaseUrl } from "../../../base";
import axios from "axios";
import UserListTable from "./UserListTable";
import AdminListTable from "./AdminListTable";
import DisabledListTable from "./DisabledListTable";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { CSVLink } from "react-csv";

const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

const UserManagement = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);

  const [userList, setUserList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [disabledList, setDisabledList] = useState([]);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [allUserRes, setAllUserRes] = useState([]);
  const [allresCSVFilename, setAllresCSVFilename] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [email, setEmail] = useState("");
  const [changeGrpUID, setChangeGrpUID] = useState("");
  const [changeStatusUID, setChangeStatusUID] = useState("");
  const [statusBody, setStatusBody] = useState("");
  const [action, setAction] = useState("");
  const [edOpr, setEDOpr] = useState("");

  const getUserList = async () => {
    if (loggedInUser.jwtToken !== undefined) {
		const { data } = await apiCall("user/getAllUser");
		setUserList(data);
	}
  };

  const getAdminList = async () => {
    if (loggedInUser.jwtToken !== undefined) {
      const { data } = await apiCall("user/getAllAdmin");
      //   axios.get(`${getBaseUrl()}/user/getAllAdmin`, {
      //     headers: {
      //       Authorization: `Bearer ${loggedInUser.jwtToken}`,
      //     },
      //   });
      // return data;
      setAdminList(data);
    }
  };

  const getAllDisabled = async () => {
    if (loggedInUser.jwtToken !== undefined) {
		const { data } = await apiCall("user/getAllDisabled");
		//   axios.get(`${getBaseUrl()}/user/getAllDisabled`, {
		//     headers: {
		//       Authorization: `Bearer ${loggedInUser.jwtToken}`,
		//     },
		//   });
		// return data;
		setDisabledList(data);
	}
  };

  // console.log("userlist:", userList);
  // console.log("disabledList:", disabledList);
  // console.log("adminList:", adminList);

  const changeStatus = async (action, email, userID) => {
    var param = {
      UserPoolId: process.env.REACT_APP_USERPOOLID,
      Username: email,
    };
    if (action === "Disable") {
      cognitoidentityserviceprovider.adminDisableUser(
        param,
        function (err, data) {
          if (err) {
            setMessage("Status change unsuccessful!");
            setStatus(false);
            setToast(true);
            return;
          } else {
              apiCall(`user/updateStatus/${userID}`, "PUT", { status: false })
					// axios
					//   .put(
					//     `${getBaseUrl()}/user/updateStatus/${userID}`,
					//     { status: false },
					//     {
					//       headers: {
					//         Authorization: `Bearer ${loggedInUser.jwtToken}`,
					//       },
					//     }
					//   )
					.then(() => {
						getAllDisabled();
						getUserList();
						getAdminList();
						setMessage("User disbaled successfully!");
						setStatus(true);
						setToast(true);
					});
          }
        }
      );
    } else {
      cognitoidentityserviceprovider.adminEnableUser(
        param,
        function (err, data) {
          if (err) {
            setMessage("Status change unsuccessful!");
            setStatus(false);
            setToast(true);
            return;
          } else {
              apiCall(`user/updateStatus/${userID}`, "PUT", { status: true })
					// axios
					//   .put(
					//     `${getBaseUrl()}/user/updateStatus/${userID}`,
					//     { status: true },
					//     {
					//       headers: {
					//         Authorization: `Bearer ${loggedInUser.jwtToken}`,
					//       },
					//     }
					//   )
					.then(() => {
						getAllDisabled();
						getUserList();
						getAdminList();
						setMessage("User enabled successfully!");
						setStatus(true);
						setToast(true);
					});
          }
        }
      );
    }
  };

  useEffect(() => {
    getUserList();
    // .then((resData1) => {
    //   setUserList(resData1);
    // });

    getAdminList();
    // .then((resData2) => {
    //   setAdminList(resData2);
    // });

    getAllDisabled();
    // .then((resData3) => {
    //   setDisabledList(resData3)
    // })
  }, [loggedInUser.UID]);

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6><a href="#" onClick={() => window.history.back()}>&lt;Back</a></h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Cancel
              </Button>
              <CSVLink
                data={allUserRes}
                headers={[
                  { label: "Assessment ID", key: "AID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={allresCSVFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose1}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to change the group to "Admin" for the said
              user?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  var params1 = {
                    GroupName: "User",
                    UserPoolId: process.env.REACT_APP_USERPOOLID,
                    Username: email,
                  };
                  cognitoidentityserviceprovider.adminRemoveUserFromGroup(
                    params1,
                    function async(err, data1) {
                      if (err) {
                        setMessage("Group change unsuccessful!");
                        setStatus(false);
                        setToast(true);
                        return;
                      } else {
                        var params2 = {
                          GroupName: "Admin",
                          UserPoolId: process.env.REACT_APP_USERPOOLID,
                          Username: email,
                        };
                        cognitoidentityserviceprovider.adminAddUserToGroup(
                          params2,
                          function async(err, data1) {
                            if (err) {
                              setMessage("Group change unsuccessful!");
                              setStatus(false);
                              setToast(true);
                              return;
                            } else {
                                apiCall(
									`user/ChangeGrpToAdmin/${changeGrpUID}`,
									"PUT"
								)
									//   axios
									// 		.put(
									// 			`${getBaseUrl()}/user/ChangeGrpToAdmin/${changeGrpUID}`,
									// 			{},
									// 			{
									// 				headers: {
									// 					Authorization: `Bearer ${loggedInUser.jwtToken}`,
									// 				},
									// 			}
									// 		)
									.then(() => {
										getUserList();
										getAdminList();
										getAllDisabled();
										setMessage(
											"Group changed successfully!"
										);
										setStatus(true);
										setToast(true);
										// getUserList().then((resData) => {
										//   setUserList(resData);
										//   setMessage("Group changed successfully!");
										//   setStatus(true);
										//   setToast(true);
										//   getAdminList().then((resData2) => {
										//     setAdminList(resData2);
										//   });
										// });
									});
                            }
                          }
                        );
                      }
                    }
                  );
                  handleClose2();
                }}
              >
                Change to Admin
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to change the group to "User" for the said
              user?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose3}>
                Close
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  var params1 = {
                    GroupName: "Admin",
                    UserPoolId: process.env.REACT_APP_USERPOOLID,
                    Username: email,
                  };
                  cognitoidentityserviceprovider.adminRemoveUserFromGroup(
                    params1,
                    function async(err, data1) {
                      if (err) {
                        setMessage("Group change unsuccessful!");
                        setStatus(false);
                        setToast(true);
                        return;
                      } else {
                        var params2 = {
                          GroupName: "User",
                          UserPoolId: process.env.REACT_APP_USERPOOLID,
                          Username: email,
                        };
                        cognitoidentityserviceprovider.adminAddUserToGroup(
                          params2,
                          function async(err, data1) {
                            if (err) {
                              setMessage("Group change unsuccessful!");
                              setStatus(false);
                              setToast(true);
                              return;
                            } else {
                                apiCall(
									`user/ChangeGrpToUser/${changeGrpUID}`,
									"PUT"
								)
									//   axios
									// 		.put(
									// 			`${getBaseUrl()}/user/ChangeGrpToUser/${changeGrpUID}`,
									// 			{},
									// 			{
									// 				headers: {
									// 					Authorization: `Bearer ${loggedInUser.jwtToken}`,
									// 				},
									// 			}
									// 		)
									.then(() => {
										getAdminList();
										getUserList();
										getAllDisabled();
										setMessage(
											"Group changed successfully!"
										);
										setStatus(true);
										setToast(true);
										// getAdminList().then((resData) => {
										//   setAdminList(resData);
										//   setMessage("Group changed successfully!");
										//   setStatus(true);
										//   setToast(true);
										//   getUserList().then((resData2) => {
										//     setUserList(resData2);
										//   });
										// });
									});
                            }
                          }
                        );
                      }
                    }
                  );
                  handleClose3();
                }}
              >
                Change to User
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {statusBody}
              <br />
              <br />
              <p>
                you must enter "
                <span style={{ color: action === "Disable" ? "red" : "green" }}>
                  {action.toLowerCase()}
                </span>
                " to proceed.
              </p>
              <input
                onChange={(e) => {
                  setEDOpr(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose4}>
                Close
              </Button>
              {action === "Disable" ? (
                <Button
                  disabled={edOpr === "disable" ? false : true}
                  variant="danger"
                  onClick={() => {
                    changeStatus(action, email, changeStatusUID);
                    handleClose4();
                  }}
                >
                  Disable
                </Button>
              ) : (
                <Button
                  disabled={edOpr === "enable" ? false : true}
                  variant="success"
                  onClick={() => {
                    changeStatus(action, email, changeStatusUID);
                    handleClose4();
                  }}
                >
                  Enable
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <Tabs
            defaultActiveKey="UserList"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="UserList" title="Enabled User List">
              <UserListTable
                userList={userList}
                setAllUserRes={setAllUserRes}
                handleShow1={handleShow1}
                setAllresCSVFilename={setAllresCSVFilename}
                setHeader={setHeader}
                setBody={setBody}
                handleShow2={handleShow2}
                setChangeGrpUID={setChangeGrpUID}
                setEmail={setEmail}
                setAction={setAction}
                setStatusBody={setStatusBody}
                handleShow4={handleShow4}
                setChangeStatusUID={setChangeStatusUID}
              />
            </Tab>
            <Tab eventKey="DisabledList" title="Disabled User List">
              <DisabledListTable
                disabledList={disabledList}
                setHeader={setHeader}
                setEmail={setEmail}
                setAction={setAction}
                setStatusBody={setStatusBody}
                handleShow4={handleShow4}
                setChangeStatusUID={setChangeStatusUID}
              />
            </Tab>
            <Tab eventKey="AdminList" title="Admin List">
              <AdminListTable
                adminList={adminList}
                setHeader={setHeader}
                handleShow3={handleShow3}
                setChangeGrpUID={setChangeGrpUID}
                setEmail={setEmail}
              />
            </Tab>
          </Tabs>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default UserManagement;
