import React from "react";
import { Form, Stack, Button } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";

const NFCListSet = (props) => {
  let editing = props.editing;
  return (
    <div className="c-main">
        <Stack direction="horizontal" gap={2}>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="number"
            placeholder="Start"
            name="startWeight"
            value={props.NFCItem.startWeight}
            onBlur={(e) => {
                // const { name, value } = e.target;
                // props.validateNFCInput(name, value, props.NFCIdx);
                props.validateNFCOverlap();
            }}
            onChange={(e) => {
                props.handleNFCChange(e, props.NFCIdx);
            }}
            />
            <div style={{height: "100%"}}>
                <p style={{margin: "auto"}}><b>-</b></p>
            </div>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="number"
            placeholder="End"
            name="endWeight"
            value={props.NFCItem.endWeight === -1000 ? "" : props.NFCItem.endWeight}
            onBlur={(e) => {
                // const { name, value } = e.target;
                // props.validateNFCInput(name, value, props.NFCIdx);
                props.validateNFCOverlap();
            }}
            onChange={(e) => {
                props.handleNFCChange(e, props.NFCIdx);
            }}
            />
            <div style={{height: "100%"}}>
                <p style={{margin: "auto"}}><b>=</b></p>
            </div>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="number"
            placeholder="Equivalent"
            name="eqWeight"
            value={props.NFCItem.eqWeight}
            onBlur={(e) => {
                props.validateNFCOverlap();
            }}
            onChange={(e) => {
                props.handleNFCChange(e, props.NFCIdx);
            }}
            />
            <div className="vr" />
            <Button
            disabled={editing}
            className="sec-x-btn"
            variant="outline-light"
            onClick={() => {
                props.handleNFCRemove(props.NFCIdx);
            }}
            >
                <FaMinus style={{ color: "#BD4B4B", fontSize: "15px" }} />
            </Button>
        </Stack>
        <br></br>
    </div>
  );
};

export default NFCListSet;
