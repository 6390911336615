import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Card, Button, Accordion, Collapse } from "react-bootstrap";
import { Steps } from "rsuite";
import { UserContext } from "../../Contexts/UserContext";
import "rsuite/dist/rsuite.min.css";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import UserResponseQuestion from "./UserResponseQuestion";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { useMediaQuery } from "react-responsive";
import SubmitButton from './SubmitButton';
import SaveAndNextButton from './SaveAndNextButton';
import arrowDown from "../../../image/Arrow-Down-icon.png";
import arrowUp from "../../../image/Arrow-Up-icon.png";


const UserResponse = () => {
  const { loginStatus, loggedInUser } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus } = useContext(AssmtContext);

  const {
    AssmtID,
    UserAssmtID,
    assmtDetails,
    allSecObj,
    step,
    secName,
    secDesc,
    allQuestion,
    onChange,
    userResponseList,
    setUserResponseList,
    submitSection,
    finalSubmit,
    assmtType,
    assmtLeft,
    setAssmtLeft,
    totalAssmt,
    grpAssmtDetails,
    grpAssmtID,
    setStep,
  } = useContext(UserContext);

  const [showDetails, setShowDetails] = useState(false);
  
	const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

  const onNext = () => onChange(step + 1);
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  let navigate = useNavigate();

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "User" ? (
        <>
          <Navigate />
          <ToastMessage toast={toast} onClose={() => setToast(!toast)} message={message} status={status} />
          <div className="response">
            <div style={{width: isMobile? "90%":"25%"}}>
              {isMobile? (
                <Stack gap={1}>
                  <div className="steps-bar">
                    <Steps current={step}>
                      {allSecObj.map((e) => (
                        <Steps.Item key={e.SecID} />
                      ))}
                    </Steps>
                  </div>
                  <img 
                    src={showDetails ? arrowUp : arrowDown} 
                    alt={showDetails ? "arrow up" : "arrow down"} 
                    style={{width: "20px", height: "20px", marginBottom: "10px", alignSelf: "center"}}  
                    onClick={toggleDetails}
                  />
                  <Collapse in={showDetails}>
                    <div style={{ width: "100%" }}>
                      <Stack gap={2}>
                        <Card>
                          <Card.Body>
                            <Card.Title style={{ marginBottom: "0rem" }}>{assmtDetails.name}</Card.Title>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Body>
                            <Card.Title>Section: {secName}</Card.Title>
                            <label className="text-muted">{secDesc}</label>
                          </Card.Body>
                        </Card>
                      </Stack>
                    </div>
                  </Collapse>
                  {showDetails ? <br /> : <></>}
                </Stack>
              )
              : (
                <Stack gap={2}>
                  <Card>
                    <Card.Body>
                      {isMobile? 
                      <Card.Title style={{marginBottom: "0rem"}}>{assmtDetails.name}</Card.Title>
                      :
                      <Card.Title>{assmtDetails.name}</Card.Title>
                      }
                    </Card.Body>
                  </Card>
                  {/* {isMobile? 
                    (<Accordion>
                      <Card>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="section-bttn">Section: {secName}</Accordion.Header>
                          <Accordion.Body style={{ padding: "0.5rem 0.5rem"}}>
                            <Card.Body style={{ padding: "0.5rem 0.5rem"}}>
                              <label className="text-muted" style={{lineHeight: "normal", fontSize: "16px"}}>{secDesc}</label>
                            </Card.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Card>
                    </Accordion> 
                    ) : (*/}
                    <Card>
                      <Card.Body>
                        <Card.Title>Section: {secName}</Card.Title>
                        <label className="text-muted">{secDesc}</label>
                      </Card.Body>
                    </Card>
                  {/* )} */}
                </Stack>
              )}
            </div>
            <div className="response-body">
              {isMobile? <></>: (
                <Stack direction="horizontal">
                  <Steps className="steps-bar" current={step}>
                    {allSecObj.map((e) => {
                      return <Steps.Item key={e.SecID} />;
                    })}
                  </Steps>
                  {step === allSecObj.length - 1 ? (
                    <SubmitButton />
                  ) : (
                    <SaveAndNextButton onNext={onNext} />
                  )}
                </Stack>
              )}
              { isMobile? <></>: <br />}
              <UserResponseQuestion isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} allQuestion={allQuestion}/>
              <br />
              {isMobile? (
                <div style={{width: "95%", paddingLeft: '9px'}}>
                  {step === allSecObj.length - 1 ? (
                    <SubmitButton />
                  ) : (
                    <SaveAndNextButton onNext={onNext} />
                  )}
                </div>
              ): <></>}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default UserResponse;
