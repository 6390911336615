import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Stack, Card, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Steps } from "rsuite";
import Navigate from "../../Navigate";
import axios from "axios";
import ViewMultiple from "./ViewMultiple";
import ViewInventory from "./ViewInventory";
import ViewLikert from "./ViewLikert";
import ViewPickOne from "./ViewPickOne";
import { getBaseUrl } from "../../../base";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import ViewLikert6 from "./ViewLikert6";
import { AssmtContext } from "../../Contexts/AssmtContext";
import moment from "moment";
import "moment-timezone";

const ViewUserResponse = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { assmtType } = useContext(AssmtContext);

  const AID = useParams().AID;
  const UAID = useParams().UAID;

  const navigate = useNavigate();

  let AGID = localStorage.getItem("AGID");
  let secAssmtType = localStorage.getItem("secAssmtType");

  const [assmtObj, setAssmtObj] = useState({});
  const [sectionAnalysisData, setSectionAnalysisData] = useState([]);
  const [secObj, setSecObj] = useState([]);
  const [secStep, setSecStep] = useState(0);
  const [singleSecObj, setSingleSecObj] = useState({});
  const [allQinSec, setAllQinSec] = useState([]);
  const [allResponse, setAllResponse] = useState([]);
  const [userAssmtObj, setUserAssmtObj] = useState([]);
  const questionRef = useRef(null);

  useEffect(() => {
    const getUserAssmtData = async () => {
      const assmtData = await apiCall(`admin/getResponseRefresh/${AID}/${UAID}`);
      await assmtData["data"].forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
        obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
      });
      setUserAssmtObj(assmtData["data"][0]);
    };

    const getUserGrpAssmtData = async () => {
      const assmtData = await apiCall(`admin/getResponseRefresh/${AGID}/${UAID}`);
      await assmtData["data"].forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
        obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
      });
      setUserAssmtObj(assmtData["data"][0]);
    };

    if (userAssmtObj.length === 0 && (AGID === "undefined" || secAssmtType === "individual")) getUserAssmtData();
    else if (userAssmtObj.length === 0 && AGID && secAssmtType === "group") getUserGrpAssmtData();
  }, [UAID, loggedInUser.jwtToken, userAssmtObj]);

  useEffect(() => {
    if (!UAID || UAID === "") return;
    const getAllResponse = async (UAID) => {
      const { data } = await apiCall(`admin/getResponseByUser/${UAID}`);
      setAllResponse(data);
    };
    if (loggedInUser.jwtToken) getAllResponse(UAID);
  }, [UAID, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!AID || AID === "") return;

    const getAssmt = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      setAssmtObj(data[0]);
    };
    if (loggedInUser.jwtToken) getAssmt(AID);

    const getSections = async (AID) => {
      const { data } = await apiCall(`admin/getSections/${AID}`);
      setSecObj(data);
    };
    if (loggedInUser.jwtToken) getSections(AID);
  }, [AID, loggedInUser.jwtToken]);

  const onChange = (nextStep) => {
    setSecStep(nextStep > secObj.length ? secObj.length : nextStep);
  };

  const onNext = () => onChange(secStep + 1);
  const onPrevious = () => onChange(secStep - 1);

  useEffect(() => {
    if (!secObj || secObj.length === 0) return;
    setSingleSecObj({
      SecID: secObj[secStep].SecID,
      sec_name: secObj[secStep].sec_name,
      sec_desc: secObj[secStep].sec_desc,
    });
  }, [secObj, secStep, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!assmtObj.AID || assmtObj.AID === "" || !singleSecObj.SecID || singleSecObj.SecID === "") return;
    const getAllQuestionBySecID = async (AID, SecID) => {
      let { data } = await apiCall(`admin/getAllQuestions/${AID}/${SecID}`);
      setAllQinSec(data);
    };
    if (loggedInUser.jwtToken) getAllQuestionBySecID(assmtObj.AID, singleSecObj.SecID);
  }, [assmtObj, singleSecObj, loggedInUser.jwtToken]);

  useEffect(() => {
    const getSectionAnalysisData = async (AID, UAID, secObj) => {
      const { data } = await apiCall(`analysis/secscore/${AID}/${UAID}`, "POST", { responses: secObj });
      const formattedData = data.map(item => ({
				...item,
				sec_score: Math.floor(item.sec_score) === item.sec_score ? `${item.sec_score}.0` : Number(item.sec_score).toFixed(1),
			  }));
      const uniqueSecName = [...new Set(secObj.map((e) => e.sec_name))];
      const namedSec = formattedData.map((e,i) => {
        return {
          sec_no: uniqueSecName[i],
          sec_score: e.sec_score,
        };
      });
      const { data: communicationScore } = await apiCall(`analysis/communicationScore/${AID}/${userAssmtObj.UID}`);
      if (communicationScore[UAID]) {
        setSectionAnalysisData([
          ...namedSec,
          {
            sec_no: "Communication",
            sec_score: Number.isInteger(communicationScore)? `${communicationScore}.0` : Number(communicationScore[UAID]).toFixed(1),
          },
        ]);
      } else setSectionAnalysisData(namedSec);
    };
    if (secObj.length !== 0 && userAssmtObj.UID) getSectionAnalysisData(AID, UAID, secObj);
  }, [secObj, loggedInUser.jwtToken, userAssmtObj]);

  useEffect(() => {
		if (questionRef.current) {
			questionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [allQinSec]);

  const columns = [
    {
      name: "Section Name",
      selector: (row) => row["sec_no"],
      sortable: true,
      wrap: true,
      minWidth: '13em'
    },
    {
      name: "Score",
      selector: (row) => row["sec_score"],
      sortable: true,
    },
    // {
    //   cell:(row) => <Button
    //   variant="outline-primary"
    //   onClick={onPrevious}
    //   disabled={secStep === 0}
    //   >
    //     Analyze
    //   </Button>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // }
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.sec_no.split(",").includes(`${secStep + 1}`),
      style: {
        backgroundColor: "#f0f7fc",
        userSelect: "none",
      },
    },
  ];
  console.log(userAssmtObj);

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6>
            <a href="#" onClick={() => navigate("/admin/viewAllResponse/"+AID)}>
              &lt;Back
            </a>
          </h6>
          <div className="response">
            <div className="w-25 fixed">
              <Stack gap={2}>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      {userAssmtObj.first_name}&nbsp;{userAssmtObj.last_name}
                    </Card.Title>
                    <label className="text-muted">Email ID: {userAssmtObj.email_ID}</label>
                    <label className="text-muted">Position: {userAssmtObj.positionOption}</label>
                    <label className="text-muted">Title: {userAssmtObj.positionTitle}</label>
                    {/* <label className="text-muted">
                      Reference date: {new Date(userAssmtObj.referenceDate).toLocaleDateString()}
                    </label> */}
                    <label className="text-muted">
                      Start time:{" "}
                      {userAssmtObj.start_Tstamp}
                    </label>
                    <label className="text-muted">
                      End time:{" "}
                      {userAssmtObj.end_Tstamp}
                    </label>
                  </Card.Body>
                </Card>
                {/* <Card>
                  <Card.Body>
                    <label className="mb-2">
                      Assessment: "{assmtObj.name}"
                    </label>
                    <label className="text-muted">{assmtObj.description}</label>
                  </Card.Body>
                </Card> */}
                <Card>
                  <Card.Body>
                    <label className="mb-2">Assessment: "{assmtObj.name}"</label>
                    <label className="mb-2">Section: "{singleSecObj.sec_name}"</label>
                    <br />
                    <label className="text-muted">{singleSecObj.sec_desc}</label>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <DataTable
                      title={
                        <div style={{ textAlign: 'center' }}>
                          <div>Analysis</div>
                          <div style={{
                            color: '#68696B',
                            textAlign: 'left',
                            fontSize: '12px',
                            lineHeight: '20px',
                            letterSpacing: 'normal',
                            opacity: 1,
                            textAlign: 'center',
                            marginTop: '5px'
                          }}>
                            Reference Date: {userAssmtObj.referenceDate}
                          </div>
                        </div>
                      }
                      columns={columns}
                      data={sectionAnalysisData}
                      defaultSortField="Section Name"
                      conditionalRowStyles={conditionalRowStyles}
                      pagination={false}
                      paginationComponentOptions={{ noRowsPerPage: false }}
                    />
                    <br></br>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        if (assmtType === "individual") navigate(`/analyze/bysection/${AID}/${userAssmtObj.UID}`,{ state: { UAID }});
                        else if (assmtType === "group") navigate(`/analyze/bysection/${AGID}/${userAssmtObj.UID}`,{ state: { UAID }});
                      }}
                      disabled={sectionAnalysisData.length === 0}
                    >
                      Analyze
                    </Button>
                  </Card.Body>
                </Card>
              </Stack>
            </div>
            <div className="viewResponse-body">
              <Stack direction="horizontal"  style={{position: "fixed", width: "62.7%"}}>
                <Button variant="outline-primary" onClick={onPrevious} disabled={secStep === 0}>
                  Prev
                </Button>
                <Steps className="steps-bar" current={secStep}>
                  {secObj.map((e) => {
                    return <Steps.Item key={e.SecID} />;
                  })}
                </Steps>
                <Button variant="outline-primary" onClick={onNext} disabled={secStep >= secObj.length - 1}>
                  Next
                </Button>
              </Stack>
              <br />
              <Stack className="question"  style={{marginTop: "2.35rem"}}>
                <ol ref={questionRef}>
                  {allQinSec.map((q, qqid) => (
                    <li key={qqid}>
                      <div className="u-question-body" key={q.QID}>
                        <Form.Label>{q.question}</Form.Label>
                        <div>
                          {q.type === "Multiple" ? (
                            <ViewMultiple q={q} UAID={UAID} allResponse={allResponse} />
                          ) : q.type === "Inventory" ? (
                            <ViewInventory q={q} UAID={UAID} allResponse={allResponse} />
                          ) : q.type === "Likert" ? (
                            <ViewLikert q={q} UAID={UAID} allResponse={allResponse} />
                          ) : q.type === "Likert-6" ? (
                            <ViewLikert6 q={q} UAID={UAID} allResponse={allResponse} />
                          ) : q.type === "Pick One" ? (
                            <ViewPickOne q={q} UAID={UAID} allResponse={allResponse} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <br />
                    </li>
                  ))}
                </ol>
              </Stack>
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
      <br />
    </div>
  );
};

export default ViewUserResponse;
