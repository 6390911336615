import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Stack, Table, Accordion, Card } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";
import Inventory from "../QuestionType/Inventory";
import Likert from "../QuestionType/Likert";
import Likert6 from "../QuestionType/Likert6";
import Multiple from "../QuestionType/Multiple";
import PickOne from "../QuestionType/PickOne";

const UserResponseQuestion = ({ isMobile, isLandscapeMobile, allQuestion }) => {

	const questionRef = useRef(null);

	// const truncateText = (text, maxLength) => {
	// 	if (text.length > maxLength) {
	// 		return text.substring(0, maxLength) + '...';
	// 	}
	// 	return text;
	// };

	useEffect(() => {
		if (questionRef.current) {
			questionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [allQuestion]);

	return (<>
			{isMobile ? (
				<Accordion>
				 {allQuestion.map((q, qqid) => (
				   <Card key={qqid}>
					 <Accordion.Item eventKey={qqid.toString()}>
					   <Accordion.Header><span className="truncate-text">{q.question}</span></Accordion.Header>
					   <Accordion.Body>
						 <Form.Label style={{marginBottom: '1rem'}}>{q.question}</Form.Label>
						 <div>
						   {q.type === "Multiple" ? (
							 <Multiple q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Inventory" ? (
							 <Inventory q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Likert" ? (
							 <Likert q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Likert-6" ? (
							 <Likert6 q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Pick One" ? (
							 <PickOne q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : (
							 <></>
						   )}
						 </div>
					   </Accordion.Body>
					 </Accordion.Item>
				   </Card>
				 ))}
			    </Accordion>
			):(
				<Stack className="question" >
					<ol ref={questionRef}>
						{allQuestion.map((q, qqid) => (
							<li key={qqid}>
								<div className="question-body" key={q.QID}>
									<Form.Label>{q.question}</Form.Label>
									<div>
										{q.type === "Multiple" ? (
											<Multiple q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Inventory" ? (
											<Inventory q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Likert" ? (
											<Likert q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Likert-6" ? (
											<Likert6 q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Pick One" ? (
											<PickOne q={q} qqid={qqid} allQuestion={allQuestion} />
										) : (
											<></>
										)}
									</div>
								</div>
								<br />
							</li>
						))}
					</ol>
				</Stack>
			)}
		</>
	);
};

export default UserResponseQuestion;
