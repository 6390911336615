import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Form, Stack, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navigate from "../../Navigate";
import EditSectionSet from "./EditSectionSet";
import ToastMessage from "../../ToastMessage";
import { SiAddthis } from "react-icons/si";
import { GiDna1 } from "react-icons/gi";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { getBaseUrl } from "../../../base";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";

import Modal from "react-modal/lib/components/Modal";
import ModalListSet from "../Assmt/ModalListSet";

import { RiSaveFill } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";

Modal.setAppElement("#root");
const EditAssmt = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus, setAssmt, validateInput, } =
    useContext(AssmtContext);

  const {
    setAssmtID,
    assmtObj,
    setAssmtObj,
    sectionList,
    handleSectionAdd,
    createAssmt,
  } = useContext(EditAssmtContext);

  let navigate = useNavigate();
  const { state } = useLocation();
  const textOnly = state.textOnly || false;
  const retrievedData = JSON.parse(localStorage.getItem('assEditType'));
  const deleteOldAssmt = retrievedData.editType;
  
  const AID = useParams().id;

  useEffect(() => {
    setAssmtID(AID);
  });

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6><a href="#" onClick={() => navigate("/admin/viewAssmt/"+AID)}>&lt;Back</a></h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />

          <Stack direction="horizontal" gap={3}>
            <label>Edit Your Assessment</label>
            <div className="ms-auto">
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => handleSectionAdd()}
              >
                {" "}
                <SiAddthis style={{ color: "#163172", fontSize: "20px" }} /> New
                Section
              </Button>
            </div>
          </Stack>
          <br />

          <div className="newAssmt">
            <div className="w-25 fixed">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Assessment Name"
                    value={assmtObj.aName}
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return { ...prevValue, aName: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Assessment Description"
                    rows={5}
                    value={assmtObj.aDesc}
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return { ...prevValue, aDesc: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Assessment URLs"
                    value={assmtObj.aUrl}
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return { ...prevValue, aUrl: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
                {sectionList.length > 0 ? (
                  <Form.Group>
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn"
                      onClick={async () => {
                        if (
                          !assmtObj.aid ||
                          !assmtObj.aName ||
                          !assmtObj.aDesc
                        ) {
                          setMessage("Assessment details missing!");
                          setStatus(false);
                          setToast(true);
                          return;
                        }
                        const isValid = validateInput(assmtObj.aUrl);
                        if (!isValid) {
                          setMessage("Invalid URL!");
                          setStatus(false);
                          setToast(true);
                          return;
                        }
                        let flag = false;
                        let e_msgs = [];
                        sectionList.forEach((sec, idx) => {
                          if (sec.sName === "" || sec.sDesc === "") {
                            flag = true;
                            e_msgs.push(`Section ${idx+1} details missing!`);
                          }
                          if(sec.qIDs.length === 0) {
                            flag = true;
                            e_msgs.push(`Section ${idx+1} has no questions!`);
                          }
                          else{
                            sec.qIDs.forEach((q, idy) => {
                              if (q.qQuestion === "" || q.qType === "") {
                                flag = true;
                                e_msgs.push(`Section ${idx+1}, Question ${idy+1} details missing!`);
                              }
                              if (q.cIDs.length === 0) {
                                flag = true;
                                e_msgs.push(`Section ${idx+1}, Question ${idy+1} has no choices!`);
                              }
                              else{
                                q.cIDs.forEach((c, idz) => {
                                  if (c.cName === "") {
                                    flag = true;
                                    e_msgs.push(`Section ${idx+1}, Question ${idy+1}, Choice ${idz+1} details missing!`);
                                  }
                                });
                              }
                            });
                          }
                        });
                        if (flag === true) {
                          setMessage(e_msgs);
                          setStatus(false);
                          setToast(true);
                          return;
                        }
                        // if (!editing) {
                        //   setMessage("Save all sections!");
                        //   setStatus(false);
                        //   setToast(true);
                        //   return;
                        // }
                        const result = await createAssmt(
                          assmtObj,
                          sectionList,
                          deleteOldAssmt,
                          textOnly
                        );
                        if (result) {
                          setMessage(
                            `Assessment ${assmtObj.aName} updated successfully!`
                          );
                          setStatus(true);
                          setToast(true);
                          const { data } = await apiCall("admin/");
                          navigate("/admin", { replace: false });
                          setAssmt(data);
						            } else if (result === false) {
                          setMessage("Assessment could not be updated!");
                          setStatus(false);
                          setToast(true);
                        }
                      }}
                    >
                      <GiDna1 style={{ color: "#163172", fontSize: "20px" }} />
                      {assmtObj.hasResponse === true && textOnly === false ? "Copy & Save" : "Save"}
                    </Button>
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Form>
							<br />
							<br />
            </div>
            <div className="assmt-b">
              {sectionList.map((singleSection, sidx) => (
                <div key={sidx}>
                  <EditSectionSet
                    i={sidx + 1}
                    sidx={sidx}
                    singleSection={singleSection}
                    textOnly={textOnly}
                  />
                  <br />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default EditAssmt;
