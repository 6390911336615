import React, { useContext, useEffect, useState } from "react";
import { Navbar, Container, Stack, Form, Button } from "react-bootstrap";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../image/Helix-logo.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaHashtag } from "react-icons/fa";
import ToastMessage from "./ToastMessage";
import { CognitoUser } from "amazon-cognito-identity-js";

const ForgotPassword = () => {
	const { Pool } = useContext(AccountContext);

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);
	const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const [passState, setPassState] = useState(false);
	const [passShow, setPassShow] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (
			password !== "" &&
			confirmPassword !== "" &&
			password === confirmPassword
		) {
			setPassState(true);
		} else {
			setPassState(false);
		}
	}, [password, confirmPassword]);

	const togglePassword = () => {
		setPassShow(!passShow);
	};

	const getUser = () => {
		return new CognitoUser({
			Username: email.toLowerCase(),
			Pool,
		});
	};

	const sendCode = (event) => {
		event.preventDefault();
		if (email === "") {
			setErrMsg("Please enter your registered Email ID!");
			return;
		}

		getUser().forgotPassword({
			onSuccess: (data) => {
				// console.log("onSuccess:", data);
				navigate("/");
				setMessage("Please check your email for the verification code");
				setStatus(true);
				setToast(true);
				return;
			},
			onFailure: (err) => {
				// console.error("onFailure:", err);
				setErrMsg(err.message);
				return;
			},
			inputVerificationCode: (data) => {
				// console.log("Input code:", data);
				setStage(2);
			},
		});
	};

	const resetPassword = (event) => {
		event.preventDefault();

		if (code === "" || password === "" || confirmPassword === "") {
			setMessage("Please input all the details");
			setStatus(false);
			setToast(true);
			return;
		}

		if (password !== confirmPassword) {
			setErrMsg("Password do not match!");
			return;
		}

		getUser().confirmPassword(code, password, {
			onSuccess: (data) => {
				// console.log("onSuccess:", data);
				setMessage("Password reset was successful");
				setStatus(true);
				setToast(true);
				navigate("/");
				return;
			},
			onFailure: (err) => {
				// console.error("onFailure:", err);
				setErrMsg(err.message);
			},
		});
	};

	return (
		<div>
			<Navbar fixed="top" className="navbar">
				<Container>
					<Navbar.Brand href="/">
						<img
							alt=""
							src={logo}
							width="auto"
							height="40"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<ToastMessage
				toast={toast}
				onClose={() => setToast(!toast)}
				message={message}
				status={status}
			/>
			<br />
			<div className="login-div">
				{stage === 1 && (
					<Stack gap={3}>
						<h1 className="text-muted">
							Reset{" "}
							<span style={{ color: "#163172" }}>Password</span>
						</h1>
						<Form onSubmit={sendCode}>
							<p className="text-muted">
								Enter the email ID associated with your account
								and we'll send you a verification code to reset
								your password
							</p>
							<Form.Group
								className="mb-3"
								controlId="formBasicEmail"
							>
								<Form.Control
									type="email"
									placeholder="Enter registered email ID"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{errMsg.length > 0 ? (
									<Form.Text
										// className="text-muted"
										style={{
											color: "#ff0000",
											fontSize: "10px",
										}}
									>
										{errMsg}
									</Form.Text>
								) : (
									<></>
								)}
							</Form.Group>
							<Button
								className="mb-3"
								variant="light"
								style={{ color: "#163172", width: "100%" }}
								type="submit"
							>
								Send verification code <FaHashtag />
							</Button>
							<p
								className="mt-3"
								style={{
									color: "#163172",
									textAlign: "center",
								}}
							>
								Back to Login? <Link to="/login">click here</Link>
							</p>
						</Form>
						<p style={{ color: "#163172" }}>
							Don’t have an account?{" "}
							<Link to="/register">Register</Link>
						</p>
					</Stack>
				)}

				{stage === 2 && (
					<Stack gap={3}>
						<h1 className="text-muted">
							Reset{" "}
							<span style={{ color: "#163172" }}>Password</span>
						</h1>
						<Form onSubmit={resetPassword}>
							<p className="text-muted">
								If the email ID is registered with helix, you
								may receive an email with the verification code.
							</p>
							<Form.Group controlId="formBasicEmail">
								<Form.Control
									className="mb-3"
									type="text"
									placeholder="Enter verification code"
									value={code}
									onChange={(e) => setCode(e.target.value)}
								/>
								<div style={{ display: "flex" }}>
									<div
										style={{
											position: "relative",
											width: "100%",
										}}
									>
										<Form.Control
											type={
												passShow ? "text" : "password"
											}
											placeholder="Enter new password"
											value={password}
											onChange={(e) =>
												setPassword(e.target.value)
											}
										/>
									</div>
									{password.length ? (
										<Button
											variant="outline-light"
											style={{ color: "#163172" }}
											onClick={togglePassword}
										>
											{passShow === false ? (
												<BsFillEyeFill />
											) : (
												<BsFillEyeSlashFill />
											)}
										</Button>
									) : (
										<></>
									)}
								</div>
								<div style={{ position: "relative" }}>
									<Form.Control
										className="mt-3 mb-3"
										type={passShow ? "text" : "password"}
										placeholder="Confirm new password"
										value={confirmPassword}
										onChange={(e) =>
											setConfirmPassword(e.target.value)
										}
									/>

									{passState === true ? (
										<BsFillCheckCircleFill
											style={{
												position: "absolute",
												top: "0.65rem",
												right: "1rem",
											}}
										/>
									) : (
										<></>
									)}
								</div>
								{errMsg.length > 0 ? (
									<Form.Text
										style={{
											color: "#ff0000",
											fontSize: "10px",
										}}
									>
										{errMsg}
									</Form.Text>
								) : (
									<></>
								)}
							</Form.Group>
							<br />
							<Button
								className="mb-3"
								variant="light"
								style={{ color: "#163172", width: "100%" }}
								type="submit"
							>
								Reset Password
							</Button>
						</Form>
					</Stack>
				)}
			</div>
		</div>
	);
};

export default ForgotPassword;
