import React, { useContext } from "react";
import { Navbar, Container, Stack, Form, Button, Nav, NavDropdown } from "react-bootstrap";
import logo from "../image/Helix-logo.png";
import { AccountContext } from "./Contexts/AccountContext";
import Navigate from "./Navigate";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";

const AboutUs = () => {
	const { loginStatus, loggedInUser } = useContext(AccountContext);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
				</>
			) : (
				<Navbar fixed="top" className="navbar">
					<Container>
						<Navbar.Brand href="/">
							<img
								alt=""
								src={logo}
								width="auto"
								height="40"
								className="d-inline-block align-top"
							/>
						</Navbar.Brand>
						{isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
					</Container>
				</Navbar>
			)}
			<br />
			<div className="ac-div">
				<Stack gap={1}>
					<h1 className="text-muted">
						About <span style={{ color: "#163172" }}>Helix</span>
					</h1>
					<hr />
					<br />
					<p>
						<h4 className="text-muted" style={{ padding: "20px" }}>
							The Helix project is a longitudinal study on the
							effectiveness of systems engineers. This tool
							reflects the results of Helix, which were published
							in our Theory of Effective Systems Engineers: Atlas
							1.0. This tool is intended to allow an individual to
							self-assess their own proficiency (knowledge,
							skills, abilities, behaviors, and cognitions) and
							career path (experiences, mentoring, education, and
							training). This can be used retrospectively or for
							future planning - for example, creating a
							proficiency profile as a “target” helps you identify
							areas of growth and comparing this with your career
							path may help you identify ways in which to grow
							those skills.
						</h4>
					</p>
				</Stack>
			</div>
		</div>
	);
};

export default AboutUs;
