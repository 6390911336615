import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { getBaseUrl } from "../../base";
import { AccountContext } from "./AccountContext";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const [AssmtID, setAssmtID] = useState("");
  const [grpAssmtID, setGrpAssmtID] = useState("");
  const [assmtType, setAssmtType] = useState("");
  const [UserAssmtID, setUserAssmtID] = useState("");
  const [userObj, setUserObj] = useState({
    first_name: "",
    last_name: "",
    email_ID: "",
    role: "User",
  });

  const [userAssmtObj, setUserAssmtObj] = useState({
    positionTitle: "",
    referenceDate: "",
    positionOption: "",
    // start_Tstamp: "",
    // end_Tstamp: "",
    submitted: false,
  });

  const [assmtDetails, setAssmtDetails] = useState({});
  const [grpAssmtDetails, setGrpAssmtDetails] = useState({});
  const [allSecObj, setAllSecObj] = useState([]);
  const [step, setStep] = useState(0);
  const [SecID, setSecID] = useState("");
  const [secName, setSecName] = useState("");
  const [secDesc, setSecDesc] = useState("");
  const [allQuestion, setAllQuestion] = useState([]);
  const [userResponseList, setUserResponseList] = useState([]);// somehow choices of previous mcqs remain
  const [userHCSList, setUserHCSList] = useState([]);
  const [assmtLeft, setAssmtLeft] = useState([]);
  const [fetchIndividualAssmt, setFetchIndividualAssmt] = useState(false);
  const [totalAssmt, setTotalAssmt] = useState(0);
  const [grpSecAttmpted, setGrpSecAttmpted] = useState([]);
  const [incompleteQuestions, setIncompleteQuestions] = useState([]);
  const [activeUsers,setActiveUsers] = useState([]);

  let AID = AssmtID;
  let AGID = grpAssmtID;
  let type = assmtType;
  let UAID = UserAssmtID;

  useEffect(() => {
    if (!AID || AID === "") return;
    const getAssmtDetails = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      console.log(data, "I am here");
      setAssmtDetails(data[0]);
      setAssmtType(data[0].type);
    };
    getAssmtDetails(AID);

    const getAllSections = async (AID) => {
      const { data } = await apiCall(`admin/getSections/${AID}`);
      setAllSecObj(data);
    };
    if (fetchIndividualAssmt) getAllSections(AID);
  }, [AID]);

  useEffect(() => {
    if (!AGID || AGID === "") return;
    const getAssmtDetails = async (AGID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AGID}`);
      setGrpAssmtDetails(data[0]);
    };
    getAssmtDetails(AGID);
  }, [AGID]);

  const onChange = (nextStep) => {
    setStep(nextStep > allSecObj.length ? allSecObj.length : nextStep);
  };

  useEffect(() => {
    if (!allSecObj || allSecObj.length === 0) return;
    setSecID(allSecObj[step].SecID);
    setSecName(allSecObj[step].sec_name);
    setSecDesc(allSecObj[step].sec_desc);
  }, [allSecObj, step]);

  useEffect(() => {
    if (!AID || AID === "" || !SecID || SecID === "") return;
    const getAllQuestionBySecID = async (AID, SecID) => {
      let { data } = await apiCall(`admin/getAllQuestions/${AID}/${SecID}`);
      data.forEach((el) => {
        if (el.type === "Pick One") {
          el["checked"] = 0;
        } else {
          el.choices.forEach((ch) => {
            ch["checked"] = 0;
          });
        }
      });
      setAllQuestion(data);
    };
    getAllQuestionBySecID(AID, SecID);
  }, [AID, SecID]);

  const createUser = async (userResgObj) => {
    await axios.post(`${getBaseUrl()}/user/createUser`, userResgObj);
  };

  const getIncompleteGrpAssmt = async (UAID, AGID) => {
    const { data } = await apiCall(`user/getIncompleteGrpAssmt/${UAID}/${AGID}`);
    setAssmtLeft(data.completed)
    setGrpSecAttmpted(data.step);
  };

  const updateUser = async (UserID, userUpdateObj) => {
    await apiCall(`user/updateUser/${UserID}`, "PUT", userUpdateObj);
  };

  const submitUserAssmt = async (UID, userAssmtObj, AID) => {
    const UAID = await apiCall(`user/takeAssmt/${AID}/${UID}`, "POST", userAssmtObj);
    return UAID;
  };

  const submitSection = async (UAID, userResponseList, AID, sSeq, sec_analysisType, nfc, AGID) => {
    try {
      let resObj;;
      let responsible = userResponseList.filter((i) => i.SecID === SecID);
      if (type === "group") {
        resObj = { AGID: AGID, responses: responsible };
      } else resObj = { responses: responsible };
      await apiCall(`user/createResponse/${AID}/${UAID}`, "POST", resObj).then(async () => {
        // if(type === "individual") {
        // 	let sSeqObj = { lastSecSubmitted: sSeq };
        // 	await apiCall(`user/updateSecSubmit/${UAID}/${AID}`, "PUT", sSeqObj)
        // 		.then(async () => {
        // 				resObj['sec_analysisType'] = sec_analysisType;
        // 				resObj['nfc'] = nfc;
        // 				console.log(resObj);
        // 				await apiCall(`analysis/${AID}/${UAID}`, "POST", resObj);
        // 		});
        // } else if(type === "group") {
        // 	let sSeqObj = { lastSecSubmitted: sSeq };
        // 	await apiCall(`user/updateSecSubmit/${UAID}/${AGID}`, "PUT", sSeqObj)
        // }
        let sSeqObj = { lastSecSubmitted: sSeq };
        await apiCall(`user/updateSecSubmit/${UAID}/${AID}`, "PUT", sSeqObj).then(async () => {
          resObj["sec_analysisType"] = sec_analysisType;
          resObj["nfc"] = nfc;
          await apiCall(`analysis/${AID}/${UAID}`, "POST", resObj);//use idexes for the sections and questions and choices
        });
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const finalSubmit = async (UAID, AID) => {
    try {
      await apiCall(`user/setAssmtSubmitted/${UAID}/${AID}`, "PUT");

      if (userHCSList.length) {
        await apiCall(`analysis/generatehcsscore/${AID}/${UAID}`, "POST", { userHCSList: userHCSList });
      }

      await apiCall(`/analysis/motivationscore/${AID}`, "PUT", { UAID });
      await apiCall(`/analysis/communicationscore/${AID}`, "PUT");

      return true;
    } catch (error) {
      return false;
    }
  };

  const set_active_users = (data) => {
     setActiveUsers(data);
  }

  // const submitResponse = async (UID, userAssmtObj, userResponseList, AID) => {
  //   try {
  //     if (userAssmtObj.end_Tstamp === "") return undefined;
  //     let resObj = { responses: userResponseList };
  //     // let UID = await axios.post(`${getBaseUrl()}/user/createUser`, userObj);
  //     // console.log("UID:", UID.data);
  //     if (UID) {
  //       let UAID = await axios.post(
  //         `${getBaseUrl()}/user/takeAssmt/${AID}/${UID}`,
  //         userAssmtObj
  //       );
  //       // console.log("UAID:", UAID.data);
  //       if (UAID) {
  //         await axios.post(
  //           `${getBaseUrl()}/user/createResponse/${AID}/${UAID.data}`,
  //           resObj
  //         );
  //       }
  //       return true;
  //     }
  //   } catch (error) {
  //     return false;
  //   }
  // };

  // console.log("AID:", AID);
  // console.log("UAID:", UAID);
  // console.log("step:", step);
  // console.log("userObj:", userObj);
  // console.log("userAssmtObj:", userAssmtObj);
  // console.log("assmtDetails:", assmtDetails);
  // console.log("allSecObj:", allSecObj);
  // console.log("allQuestion:", allQuestion);
  // console.log("userResponseList:", userResponseList);

  // const submitResponse = async (
  //   userObj,
  //   userAssmtObj,
  //   userResponseList,
  //   AID
  // ) => {
  //   try {
  //     if (userAssmtObj.end_Tstamp === "") return undefined;
  //     let resObj = { responses: userResponseList };
  //     let UID = await axios.post(`${getBaseUrl()}/user/createUser`, userObj);
  //     // console.log("UID:", UID.data);
  //     if (UID) {
  //       let UAID = await axios.post(
  //         `${getBaseUrl()}/user/takeAssmt/${AID}/${UID.data}`,
  //         userAssmtObj
  //       );
  //       // console.log("UAID:", UAID.data);
  //       if (UAID) {
  //         await axios.post(
  //           `${getBaseUrl()}/user/createResponse/${AID}/${UAID.data}`,
  //           resObj
  //         );
  //       }
  //       return true;
  //     }
  //   } catch (error) {
  //     return false;
  //   }
  // };

  return (
    <UserContext.Provider
      value={{
        AssmtID,
        setAssmtID,
        UserAssmtID,
        setUserAssmtID,
        userObj,
        setUserObj,
        userAssmtObj,
        setUserAssmtObj,
        assmtDetails,
        setAssmtDetails,
        allSecObj,
        setAllSecObj,
        step,
        setStep,
        SecID,
        setSecID,
        secName,
        setSecName,
        secDesc,
        setSecDesc,
        allQuestion,
        setAllQuestion,
        onChange,
        userResponseList,
        setUserResponseList,
        userHCSList,
        setUserHCSList,
        createUser,
        updateUser,
        // submitResponse,
        submitUserAssmt,
        submitSection,
        finalSubmit,
        setAssmtType,
        assmtType,
        grpAssmtDetails,
        setGrpAssmtDetails,
        assmtLeft,
        setAssmtLeft,
        fetchIndividualAssmt,
        setFetchIndividualAssmt,
        totalAssmt,
        setTotalAssmt,
        grpAssmtID,
        setGrpAssmtID,
        getIncompleteGrpAssmt,
        grpSecAttmpted,
        incompleteQuestions,
        setIncompleteQuestions,
        activeUsers,
        set_active_users
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
