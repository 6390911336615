import React, { useContext } from "react";
import { Button, Card, Stack, Form } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import Navigate from "../../Navigate";
import { useNavigate } from "react-router-dom";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";

const Profile = () => {
	const { loginStatus, loggedInUser } = useContext(AccountContext);

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);

	const navigate = useNavigate();

	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
					<h6><a href="#" onClick={() => navigate("/user/dashboard/"+loggedInUser.UID)}>&lt;Back</a></h6>
					<ToastMessage
						toast={toast}
						onClose={() => setToast(!toast)}
						message={message}
						status={status}
					/>
					<br />
					<br />
					<div className="profile-card">
						<Card>
							<Card.Header className="text-center">
								Your Profile Details
							</Card.Header>
							<Card.Body>
								<Stack gap={3} className="profile-details">
									<Stack direction="horizontal">
										<Form.Label
											column
											sm={3}
											className="userAssmt-label"
										>
											<b>First Name:</b>
										</Form.Label>
										<label>{loggedInUser.first_name}</label>
									</Stack>
									<Stack direction="horizontal">
										<Form.Label
											column
											sm={3}
											className="userAssmt-label"
										>
											<b>Last Name:</b>
										</Form.Label>
										<label>{loggedInUser.last_name}</label>
									</Stack>
									<Stack direction="horizontal">
										<Form.Label
											column
											sm={3}
											className="userAssmt-label"
										>
											<b>Email ID:</b>
										</Form.Label>
										<label>{loggedInUser.email_ID}</label>
									</Stack>
									<Stack direction="horizontal">
										<Form.Label
											column
											sm={3}
											className="userAssmt-label"
										>
											<b>Group:</b>
										</Form.Label>
										<label>{loggedInUser.group}</label>
									</Stack>
								</Stack>
							</Card.Body>
							<Card.Footer className="text-center">
								<Button
									variant="outline-secondary"
									onClick={() => {
										navigate(
											`/user/editProfile/${loggedInUser.UID}`
										);
									}}
								>
									Edit
								</Button>
							</Card.Footer>
						</Card>
					</div>
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default Profile;
