import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../Contexts/AccountContext";
import { useNavigate } from "react-router-dom";
import Navigate from "../../Navigate";
import AccessDenied from "../../AccessDenied";
import { getBaseUrl } from "../../../base";
import axios from "axios";
import ResultTable from "./ResultTable";
import IncompleteResultTable from "./IncompleteResultTable";
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AssmtContext } from "../../Contexts/AssmtContext";
import moment from 'moment';
import 'moment-timezone';

const Results = () => {
	const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
	const { assmt, toast, setToast, message, setMessage, status, setStatus } =
		useContext(AssmtContext);
	const [resultList, setResultList] = useState([]);
	const [incompleteList, setIncompleteList] = useState([]);

	// const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);

	const [csvFilename, setCSVfilename] = useState("");
	const [headerName, setHeaderName] = useState("");
	const [userRes, setUserRes] = useState([]);
	const [bodyName, setBodyName] = useState("");
	const [deleteOpr, setDeleteOpr] = useState("");

	const handleClose1 = () => setShow1(false);
	const handleShow1 = () => setShow1(true);
	const handleClose2 = () => setShow2(false);
	const handleShow2 = () => setShow2(true);

	const navigate = useNavigate();

	const [UAIDtoDelete, setUAIDtoDelete] = useState("");
	const [assmtToDelete, setAssmtToDelete] = useState("");

	const getResultList = async (UID) => {
		const { data } = await apiCall(`user/getAllResults/${UID}`);
		
		data.forEach((obj) => {
			obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        	obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
    	    obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
		});
		// setResultList(data);
		return data;
	};

	const getIncompleteList = async (UID) => {
		const { data } = await apiCall(`user/getAllIncomplete/${UID}`);
		data.forEach((obj) => {
			obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
	        obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
		});
		// setIncompleteList(data);
		return data;
	};

	useEffect(() => {
		getResultList(loggedInUser.UID).then((data1) => {
			setResultList(data1);
		});
		getIncompleteList(loggedInUser.UID).then((data2) => {
			setIncompleteList(data2);
		});
	}, [loggedInUser.UID]);

	const handleOnClickDelete = async (id) => {
		let deleteUserRes = await apiCall(`admin/deleteUserRes/${id}`, "DELETE");
		// axios.delete(
		// 	`${getBaseUrl()}/admin/deleteUserRes/${id}`,
		// 	{
		// 		headers: {
		// 			Authorization: `Bearer ${loggedInUser.jwtToken}`,
		// 		},
		// 	}
		// );
		if (deleteUserRes) {
			await getIncompleteList(loggedInUser.UID).then((resData) => {
				setIncompleteList(resData);
				handleClose2();
				// window.location.reload();
				setMessage("Incomplete response deleted!");
				setStatus(true);
				setToast(true);
			});
		}
	};

	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
					<h6><a href="#" onClick={() => navigate("/user/dashboard/"+loggedInUser.UID)}>&lt;Back</a></h6>
					<Modal
						show={show1}
						onHide={handleClose1}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>{headerName}</Modal.Title>
						</Modal.Header>
						<Modal.Body>{bodyName}</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose1}>
								Cancel
							</Button>
							<CSVLink
								data={userRes}
								headers={[
									{ label: "Section Name", key: "sec_name" },
									{ label: "Question", key: "question" },
									{ label: "Type", key: "type" },
									{ label: "Choice picked", key: "choice" },
									{
										label: "Weightage of choice",
										key: "weightage",
									},
									{
										label: "Weightage of response",
										key: "response",
									},
								]}
								filename={csvFilename}
								target="_blank"
							>
								<Button
									variant="success"
									onClick={handleClose1}
								>
									Download
								</Button>
							</CSVLink>
						</Modal.Footer>
					</Modal>
					<Modal
						show={show2}
						onHide={handleClose2}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>{assmtToDelete}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete your incomplete
							response for the said assessment ?
							<br />
							<br />
							<p>
								you must enter "
								<span style={{ color: "red" }}>delete</span>" to
								proceed.
							</p>
							<input
								onChange={(e) => {
									setDeleteOpr(e.target.value);
								}}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose2}>
								Close
							</Button>
							<Button
								disabled={deleteOpr === "delete" ? false : true}
								variant="danger"
								onClick={() => {
									handleOnClickDelete(UAIDtoDelete);
									handleClose2();
								}}
							>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>
					<br />					
					{incompleteList.length > 0 ? (
						<>
							<p>Incomplete assessments:</p>
							<IncompleteResultTable
								incompleteList={incompleteList}
								handleShow2={handleShow2}
								setUAIDtoDelete={setUAIDtoDelete}
								setAssmtToDelete={setAssmtToDelete}
							/>
						</>
					) : (
						<></>
					)}
					<br />
					<br />
					<label>
						Completed assessments for {loggedInUser.first_name}{" "}
						{loggedInUser.last_name}:
					</label>
					<br />
					<br />
					{resultList.length > 0 ? (
						<>
							<ResultTable
								resultList={resultList}
								setCSVfilename={setCSVfilename}
								setHeaderName={setHeaderName}
								setUserRes={setUserRes}
								handleShow1={handleShow1}
								setBodyName={setBodyName}
							/>
						</>
					) : (
						<>
							<br />
							<p>No response found!</p>
						</>
					)}		
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default Results;
