import React, { useContext } from "react";
import Navigate from "./Navigate";
import notfound from "../image/pagenotfound.svg";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AccountContext } from "./Contexts/AccountContext";
import { useMediaQuery } from "react-responsive";

const PageNotFound = () => {
	const { loginStatus, loggedInUser } = useContext(AccountContext);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	return (
		<div>
			<Navigate />
			<div className="confirm">
				<Image
					className="flex justify-content-center"
					src={notfound}
					alt="No Image"
					width={isMobile? "388":"450"}
					height="450"
				/>
				<br />
				<br />
				<h3>Page not found !</h3>

				{loginStatus === true && loggedInUser.group === "Admin" ? (
					<>
						<p>
							Take me to <Link to="/admin">Admin page</Link>{" "}
						</p>
					</>
				) : loginStatus === true && loggedInUser.group === "User" ? (
					<>
						<p>
							Take me to{" "}
							<Link to={`/user/dashboard/${loggedInUser.UID}`}>
								Dashboard
							</Link>{" "}
						</p>
					</>
				) : (
					<p>
						Login or Register with <Link to="/">Helix</Link> to
						continue
					</p>
				)}
			</div>
		</div>
	);
};

export default PageNotFound;
