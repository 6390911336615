import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AssmtContext } from "../../Contexts/AssmtContext";

const AdminListTable = (props) => {
  const { assmt, toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  const handleShow3 = props.handleShow3;
  const setHeader = props.setHeader;
  const setChangeGrpUID = props.setChangeGrpUID;
  const setEmail = props.setEmail;

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.adminList,
      columns: [
        {
          className: "dt-body-left",
          title: "First Name",
          data: "first_name",
        },
        { className: "dt-body-left", title: "Last Name", data: "last_name" },
        {
          className: "dt-body-left",
          title: "Email ID",
          data: "email_ID",
        },
        {
          className: "dt-body-left",
          title: "Group",
          data: "group_name",
        },
        {
          className: "dt-body-left",
          title: "Email Verified",
          data: "email_verified",
          render: function (data) {
            if (data === 0) {
              return "False";
            } else {
              return "True";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Consent",
          data: "consent",
          render: function (data) {
            if (data === 0) {
              return "No";
            } else {
              return "Yes";
            }
          },
        },
        {
          className: "dt[-head|-body]-center",
          title: "Change group",
          defaultContent: `<Button class="user-btn btn btn-outline-secondary btn-sm">
                Set User
              </Button>`,
        },
      ],
      destroy: true,
    });
    $("#example4 tbody").on("click", ".user-btn", async function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setChangeGrpUID(data.UID);
        setEmail(data.email_ID);
        setHeader(`${data.first_name} ${data.last_name}`);
        handleShow3();
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.adminList]);

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left"
        width="100%"
        id="example4"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Group</th>
            <th>Email Verified</th>
            <th>Consent</th>
            <th>Change group</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default AdminListTable;
