import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Stack, Button, Badge, Modal, Form } from "react-bootstrap";
import { AccountContext } from "../Contexts/AccountContext";
import { AssmtContext } from "../Contexts/AssmtContext";
import AccessDenied from "../AccessDenied";
import Navigate from "../Navigate";
import ToastMessage from "../ToastMessage";
import { CSVLink } from "react-csv";
import { SiAddthis } from "react-icons/si";
import { FaFolderOpen, FaDatabase, FaEye } from "react-icons/fa";
import { CheckboxDropdown } from "../CheckboxDropdown";
import moment from "moment";
import 'moment-timezone';

const Admin = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const {
    assmt,
    toast,
    setToast,
    message,
    status,
    setPublishedFilter,
    isLoadingAssmt,
    setGroupFilter,
    yearsFilter,
    setYearsFilter,
    setSearchText,
    setAssmtType
  } = useContext(AssmtContext);

  const [masterExport, setMasterExport] = useState([]);
  const [show, setShow] = useState(false);
  const [reLoad, setReLoad] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleOnClickView = (id, type) => {
    setAssmtType(type);
    if (type === "individual") {
      navigate(`/admin/viewAssmt/${id}`);
      localStorage.setItem('AGID', undefined);
    }
    else if (type === "group") {
      navigate(`/admin/viewGrpAssmt/${id}`);
      localStorage.setItem('AGID', id);
    }
  };

  const generateMasterExport = async () => {
    const { data } = await apiCall("admin/masterExport");
    setMasterExport(data);
  };

  useEffect(() => {
    return () => {
      setSearchText("");
    };
  }, []);

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Helix</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Master export for all user response for all published assessent is
              genereted as .csv file and ready to download!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <CSVLink
                data={masterExport}
                headers={[
                  { label: "Assessment ID", key: "AID" },
                  { label: "User ID", key: "UID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={`Helix Master Export (exported on ${new Date().toLocaleDateString()})`}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <Stack direction="horizontal" gap={3}>
            <label>Assessment List:</label>
            <div className="ms-auto">
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => navigate("/admin/questionBank")}
              >
                <FaFolderOpen style={{ color: "#163172", fontSize: "20px" }} />{" "}
                Question Bank
              </Button>{" "}
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  generateMasterExport();
                  handleShow();
                }}
              >
                {" "}
                <FaDatabase
                  style={{ color: "#163172", fontSize: "20px" }}
                />{" "}
                Master Export
              </Button>{" "}
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  navigate("/admin/newAssmt");
                }}
              >
                {" "}
                <SiAddthis style={{ color: "#163172", fontSize: "20px" }} /> New
                Assessment
              </Button>
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  navigate("/admin/newAssmtGroup");
                }}
              >
                {" "}
                <SiAddthis style={{ color: "#163172", fontSize: "20px" }} /> New
                Assessment Group
              </Button>
            </div>
          </Stack>
          <br />
          <Stack direction="horizontal" className="my-2 mb-4" gap={4}>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Published:</p>
              <Form.Select
                aria-label="Published Select"
                onChange={(e) => {
                  if (["yes", "no"].includes(e.target.value))
                    setPublishedFilter(e.target.value);
                  else setPublishedFilter(null);
                }}
              >
                <option value="all">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Select>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Assessment Type:</p>
              <Form.Select
                aria-label="Published Select"
                onChange={(e) => {
                  if (["group", "individual"].includes(e.target.value))
                    setGroupFilter(e.target.value);
                  else setGroupFilter(null);
                }}
              >
                <option value="all">All</option>
                <option value="group">Group</option>
                <option value="individual">Individual</option>
              </Form.Select>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Year:</p>
              <CheckboxDropdown
                items={Object.keys(yearsFilter) || []}
                onChange={(changeObj) => {
                  setYearsFilter({
                    ...yearsFilter,
                    [changeObj.name]: changeObj.checked,
                  });
                }}
              />
            </Stack>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <Form.Control
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value.trim());
                }}
              />
            </div>
          </Stack>
          {isLoadingAssmt ? (
            <p>Loading...</p>
          ) : assmt.length > 0 ? (
            <div className="assmt-list">
              <Stack gap={3}>
                {assmt.map((val, key) => {
                  return (
                    <Card key={key}>
                      <Card.Header>
                        <Stack direction="horizontal" gap={2}>
                          {val.published === "Yes" ? (
                            <Badge bg="success">
                              Published: {val.published}
                            </Badge>
                          ) : (
                            <Badge bg="danger">
                              Published: {val.published}
                            </Badge>
                          )}
                          <Badge bg="secondary">Type: {val["type"]}</Badge>{" "}
                        </Stack>
                      </Card.Header>
                      <Card.Body>
                        <Stack
                          className="assmt-card-list"
                          direction="horizontal"
                        >
                          <div className="assmt-details">
                            <Card.Title>{val.name}</Card.Title>
                            <Card.Text>{val.description}</Card.Text>
                            <Badge bg="secondary">
                              Created on:{" "}
                              {moment.utc(val.TOC).tz("America/New_York").format("MM/DD/YYYY")}
                            </Badge>{" "}
                            {val.TOP !== null ? (
                              <Badge bg="secondary">
                                Published on:{" "}
                                {moment.utc(val.TOP).tz("America/New_York").format("MM/DD/YYYY")}
                              </Badge>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="assmt-options">
                            <Button
                              variant="outline-light"
                              onClick={() =>
                                handleOnClickView(val.AID, val.type)
                              }
                            >
                              <FaEye
                                style={{ color: "#1E56A0", fontSize: "20px" }}
                              />
                            </Button>{" "}
                          </div>
                        </Stack>
                      </Card.Body>
                    </Card>
                  );
                })}
              </Stack>
            </div>
          ) : (
            <p>The List is empty!</p>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
      <br />
      <br />
    </div>
  );
};

export default Admin;
