import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";

const DisabledListTable = (props) => {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  const setChangeStatusUID = props.setChangeStatusUID;
  const setEmail = props.setEmail;
  const setHeader = props.setHeader;
  const setStatusBody = props.setStatusBody;
  const setAction = props.setAction;
  const handleShow4 = props.handleShow4;

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.disabledList,
      columns: [
        {
          className: "dt-body-left",
          title: "First Name",
          data: "first_name",
        },
        { className: "dt-body-left", title: "Last Name", data: "last_name" },
        {
          className: "dt-body-left",
          title: "Email ID",
          data: "email_ID",
        },
        {
          className: "dt-body-left",
          title: "Group",
          data: "group_name",
        },
        {
          className: "dt[-head|-body]-center",
          title: "Set Enabled",
          defaultContent: `<Button class="enable-btn btn btn-outline-success btn-sm">
                    Enable
                  </Button>`,
        },
      ],
      destroy: true,
    });
    $("#example5 tbody").on("click", ".enable-btn", async function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setChangeStatusUID(data.UID);
        setEmail(data.email_ID);
        setHeader(`${data.first_name} ${data.last_name}`);
        setStatusBody("Are you sure you want to enable the above said user?");
        setAction("Enable");
        handleShow4();
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.disabledList]);

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left"
        width="100%"
        id="example5"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Group</th>
            <th>Set Enabled</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default DisabledListTable;
