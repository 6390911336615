import React, { createContext, useState, useEffect, useContext } from "react";
import { AccountContext } from "./AccountContext";
const { v4 } = require("uuid");

export const AssmtContext = createContext();

const AssmtContextProvider = ({ children }) => {
  /**
   * @typedef {Object} AccountContextValues
   * @property {*} loggedInUser
   * @property {import("./AccountContext").ApiCallFunc} apiCall
   */

  /**
   * @type {AccountContextValues}
   */
  const { loggedInUser, apiCall } = useContext(AccountContext);

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  const [assmt, setAssmt] = useState([]);
  const [questionBank, setQuestionBank] = useState([]);
  const [publishedFilter, setPublishedFilter] = useState(null);
  const [groupFilter, setGroupFilter] = useState(null);
  const [isLoadingAssmt, setLoadingAssmt] = useState(true);
  const [yearsFilter, setYearsFilter] = useState({});
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [assmtType, setAssmtType] = useState();

  useEffect(() => {
    const getAssmtList = async () => {
      setLoadingAssmt(true);
      const params = {};
      if (publishedFilter) params.published = publishedFilter;
      if (groupFilter) params.group = groupFilter;
      const { data } = await apiCall("admin", "GET", {}, {}, params);
      setUnfilteredData(data);
      if (Object.keys(yearsFilter).length === 0) {
        const years = {};
        data.forEach((assmt) => {
          if (assmt.TOC) {
            const date = new Date(assmt.TOC);
            const year = date.getFullYear();
            years[year] = true;
          }
        });
        setYearsFilter(years);
      }
    };
    if (loggedInUser.jwtToken) {
      getAssmtList();
    }
  }, [loggedInUser, publishedFilter, groupFilter]);

  useEffect(() => {
    if ((!yearsFilter || !Object.values(yearsFilter).includes(false)) && searchText.trim() === "") {
      setAssmt(unfilteredData);
    } else {
      setLoadingAssmt(true);
      const yearsFilteredApplied = Object.values(yearsFilter).includes(false);
      const searchFilterApplied = searchText.trim() !== "";
      const filteredAssmt = unfilteredData.filter((a) => {
        let yearRes = true;
        if (yearsFilteredApplied) {
          if (!a.TOC) yearRes = false;
          else {
            const date = new Date(a.TOC);
            const year = date.getFullYear();
            yearRes = yearsFilter[year];
          }
        }
        let searchRes = true;
        if (searchFilterApplied) {
          searchRes = a.name.toLowerCase().includes(searchText.trim().toLowerCase());
        }
        return searchRes && yearRes;
      });
      setAssmt(filteredAssmt);
    }
    setLoadingAssmt(false);
  }, [yearsFilter, unfilteredData, searchText]);

  const getQuestionBankList = async () => {
    const { data } = await apiCall("admin/getAllQfromBank");
    setQuestionBank(data);
  };

  useEffect(() => {
    if (loggedInUser.jwtToken) {
      getQuestionBankList();
    }
  }, [loggedInUser]);

  const [assmtObj, setAssmtObj] = useState({
    aid: v4(),
    aName: "",
    aDesc: "",
    aUrl: "",
  });

  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?([\\da-z.-]+)\\.([a-z.]{2,6})([\\/\\w .-]*)*\\/?$"
  );
  
  const validateInput = (input) => {
    if(input.trim() === "") return true;
    const urls = input.split(",");
    for (let url of urls) {
      if (!urlPattern.test(url.trim())) {
        return false;
      }
    }
    return true;
  };

  const [sectionList, setSectionList] = useState([]);

  const handleSectionAdd = () => {
    setSectionList([
      ...sectionList,
      {
        sName: "",
        sDesc: "",
        qIDs: [],
        nfc: false,
        nfcData: [],
        sAnalysisType: "",
      },
    ]);
  };

  const handleSectionRemove = (idx) => {
    const newSectionList = [...sectionList];
    newSectionList.splice(idx, 1);
    setSectionList(newSectionList);
  };

  const handleSectionChange = (e, index) => {
    const { name, value } = e.target;
    const newSectionList = [...sectionList];
    newSectionList[index][name] = value;
    setSectionList(newSectionList);
  };

  const handleSectionList = (array, index) => {
    const newSectionList = [...sectionList];
    newSectionList[index]["qIDs"] = array;
    setSectionList(newSectionList);
  };

  const handleSectionNFCList = (array, index) => {
    const newSectionList = [...sectionList];
    newSectionList[index]["nfcData"] = array;
    setSectionList(newSectionList);
  };

  const createAssmt = async (assmtObj, sectionList) => {
    try {
      let assmt = {
        AID: assmtObj.aid,
        name: assmtObj.aName,
        desc: assmtObj.aDesc,
        url: assmtObj.aUrl,
        type: "individual",
      };
      let assmtDone = await apiCall("admin/createAssmt", "POST", assmt);
      console.log(assmtDone);
      if (assmtDone.data.created) {
        await sectionList.forEach(async (sObj, sSeq) => {
          let newSection = await apiCall(`admin/createSection/${assmtObj.aid}`, "POST", {
            sec_name: sObj.sName,
            sec_desc: sObj.sDesc,
            sec_analysisType: sObj.sAnalysisType,
            sSeq: sSeq,
            nfc: sObj.nfc,
          });
          if (newSection) {
            await sObj.qIDs.forEach(async (qObj, qSeq) => {
              let cList = [];
              qObj.cIDs.forEach((c, cSeq) => {
                let cObj = {
                  choice: c.cName,
                  weightage: c.weight,
                  cSeq: cSeq,
                  communication: c.communication,
                  motivType: c.motivType,
                };
                cList.push(cObj);
              });

              await apiCall(`admin/createQuestion/${assmtObj.aid}/${newSection.data}`, "POST", {
                question: qObj.qQuestion,
                type: qObj.qType,
                choices: cList,
                qSeq: qSeq,
                hcs: qObj.hcs,
                motiv: qObj.motiv,
                CuAr: qObj.CuAr,
                CuArType: qObj.CuArType,
              });
            });

            if (sObj.nfcData.length !== 0) {
              await apiCall(`admin/createNfc/${assmtObj.aid}/${newSection.data}`, "POST", {
                nfcData: sObj.nfcData,
              });
            }
          }
        });
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const createAssmtGrp = async (assmtGrp, assmtList) => {
    try {
      let assmtDone = await apiCall("admin/createAssmt", "POST", assmtGrp);

      if (assmtDone) {
        await assmtList.forEach(async (assmtId, aSeq) => {
          await apiCall(`admin/createAssmtGrp/${assmtGrp.AID}`, "POST", {
            AID: assmtId,
            aSeq: aSeq,
          });
        });
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const exportSingleResToCSV = async (UID, UAID, AID) => {
    const { data } = await apiCall("admin/exportSingleResToCSV", "POST", {
      UID: UID,
      UAID: UAID,
      AID: AID,
    });
    return data;
  };

  const exportUserResForAllAssmtToCSV = async (UID) => {
    const { data } = await apiCall(`admin/exportUserResForAllAssmtToCSV/${UID}`);
    return data;
  };

  return (
    <AssmtContext.Provider
      value={{
        toast,
        setToast,
        message,
        setMessage,
        status,
        setStatus,
        assmt,
        assmtObj,
        setAssmt,
        setAssmtObj,
        sectionList,
        setSectionList,
        questionBank,
        getQuestionBankList,
        handleSectionAdd,
        handleSectionRemove,
        handleSectionChange,
        handleSectionList,
        handleSectionNFCList,
        createAssmt,
        createAssmtGrp,
        exportSingleResToCSV,
        exportUserResForAllAssmtToCSV,
        isLoadingAssmt,
        setPublishedFilter,
        setGroupFilter,
        yearsFilter,
        setYearsFilter,
        setSearchText,
        assmtType,
        setAssmtType,
        validateInput,
      }}
    >
      {children}
    </AssmtContext.Provider>
  );
};

export default AssmtContextProvider;
