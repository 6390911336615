import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigate from "../../Navigate";
import UserResponseTable from "./UserResponseTable";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { Button, Modal, FormLabel } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AssmtContext } from "../../Contexts/AssmtContext";
import moment from 'moment';
import 'moment-timezone';

const ViewAllResponse = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { assmtType, setAssmtType } = useContext(AssmtContext);

  const navigate = useNavigate();

  const param = useParams();
  const AID = param.id;
  let assmtData = JSON.parse(localStorage.getItem('viewResAssmtName'));
  let assmtName = assmtData['assmtName'];
  const [userAssmtDetails, setUserAssmtDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [csvFilename, setCSVfilename] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [userRes, setUserRes] = useState([]);
  const [bodyName, setBodyName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getResponse = async () => {
      let response;
      if(assmtType) {
        if(assmtType === "individual") {
          response = await apiCall(`admin/getResponse/${AID}`);
        } else if(assmtType === "group") {
          response = await apiCall(`admin/getGrpAssmtResponse/${AID}`);
        }
      } else {
        const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
        let type = data[0]["type"];
        setAssmtType(data[0]["type"]);
        if(type === "individual") {
          response = await apiCall(`admin/getResponse/${AID}`);
        } else if(type === "group") {
          response = await apiCall(`admin/getGrpAssmtResponse/${AID}`);
        }
      }
      let data = response["data"];
      data.forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
        obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
      });
      setUserAssmtDetails(data);
    };
    if (loggedInUser.jwtToken) getResponse();

    const getAssmtDetails = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      assmtName = data[0]["name"];
    }
    if(!assmtName) getAssmtDetails(AID);

  }, [loggedInUser.jwtToken, assmtType]);

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6><a href="#" onClick={() => navigate("/admin/viewAssmt/"+AID)}>&lt;Back</a></h6>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyName}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <CSVLink
                data={userRes}
                headers={[
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={csvFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <FormLabel>Response list for Assessment "{assmtName}"</FormLabel>
          <br />
          <br />
          <UserResponseTable
            assmtID={AID}
            userAssmtDetails={userAssmtDetails}
            setCSVfilename={setCSVfilename}
            setHeaderName={setHeaderName}
            setUserRes={setUserRes}
            handleShow={handleShow}
            setBodyName={setBodyName}
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ViewAllResponse;
