import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { UserContext } from "./Contexts/UserContext";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import ToastMessage from "./ToastMessage";
import {
	Navbar,
	Nav,
	Container,
	Stack,
	Form,
	Button,
	Row,
	Col,
	NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../image/Helix-logo.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
const AWS = require("aws-sdk");

AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});
var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

const Register = () => {
	const { Pool } = useContext(AccountContext);

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);

	const { createUser } = useContext(UserContext);

	const navigate = useNavigate();

	const setCognitoUserAttribute = (attributeKey, attributeValue) => {
		let data = {
			Name: attributeKey,
			Value: attributeValue,
		};

		return new CognitoUserAttribute(data);
	};

	const [name, setName] = useState("");
	const [family_name, setFamily_Name] = useState("");
	const [email, setEmail] = useState("");
	const group_name = "User";
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passState, setPassState] = useState(false);
	const [passShow, setPassShow] = useState(false);
	const [consent, setConsent] = useState(true);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	useEffect(() => {
		if (
			password !== "" &&
			confirmPassword !== "" &&
			password === confirmPassword
		) {
			setPassState(true);
		} else {
			setPassState(false);
		}
	}, [password, confirmPassword]);

	const togglePassword = () => {
		setPassShow(!passShow);
	};

	const [errMsg, setErrMsg] = useState("");

	// const UserPool = new CognitoUserPool(poolData);

	const onRegister = (event) => {
		event.preventDefault();

		if (
			name === "" ||
			family_name === "" ||
			email === "" ||
			password === "" ||
			confirmPassword === ""
		) {
			setMessage("Please input all the details");
			setStatus(false);
			setToast(true);
			return;
		}

		if (password !== confirmPassword) {
			setErrMsg("Password do not match!");
			return;
		}

		let attributeList = [];

		attributeList.push(setCognitoUserAttribute("name", name));
		attributeList.push(setCognitoUserAttribute("family_name", family_name));
		// attributeList.push(setCognitoUserAttribute("custom:role", role));

		Pool.signUp(email, password, attributeList, null, (err, data) => {
			if (err) {
				console.error(err.message);
				setErrMsg(err.message);
			} else {
				var params = {
					GroupName: group_name,
					UserPoolId: process.env.REACT_APP_USERPOOLID,
					Username: email,
				};
				cognitoidentityserviceprovider.adminAddUserToGroup(
					params,
					function async(err, data1) {
						if (err) {
							console.log(err, "Cannot add to group!");
							setMessage("Cannot add to group!");
							setStatus(false);
							setToast(true);
						} else {
							let userInfo = {};
							userInfo["UID"] = data.userSub;
							userInfo["first_name"] = name;
							userInfo["last_name"] = family_name;
							userInfo["email_ID"] = email;
							userInfo["group_name"] = group_name;
							userInfo["email_verified"] = false;
							userInfo["consent"] = consent;
							let addUser = createUser(userInfo);
							if (addUser) {
								setMessage(
									"Check your email for verification link before logging in!"
								);
								setStatus(true);
								setToast(true);
								navigate("/login");
							}
						}
					}
				);
			}
		});
	};

	return (
		<div>
			<Navbar fixed="top" className="navbar">
				<Container>
					<Navbar.Brand href="/">
						<img
							alt=""
							src={logo}
							width="auto"
							height="40"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
				</Container>
			</Navbar>
			<ToastMessage
				toast={toast}
				onClose={() => setToast(!toast)}
				message={message}
				status={status}
			/>
			<br />
			<div className="register-div">
				<Stack gap={3}>
					<h1 className="text-muted text-center">
						Register with{" "}
						<span style={{ color: "#163172" }}>Helix</span>
					</h1>
					<Form onSubmit={onRegister}>
						<Form.Group className="mb-3">
							<Row>
								<Col>
									<Form.Control
										type="text"
										placeholder="First name"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
									/>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Last name"
										value={family_name}
										onChange={(e) =>
											setFamily_Name(e.target.value)
										}
									/>
								</Col>
							</Row>
							<br />
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<div className="mb-3">
								<Form.Text className="text-muted">
									Your email address will be your username.
								</Form.Text>
							</div>

							<div style={{ display: "flex" }}>
								<div
									style={{
										position: "relative",
										width: "100%",
									}}
								>
									<Form.Control
										// className="mt-3"
										type={passShow ? "text" : "password"}
										placeholder="Enter password"
										value={password}
										onChange={(e) =>
											setPassword(e.target.value)
										}
									/>
									{/* {passState === true ? (
                    <BsFillCheckCircleFill
                      style={{
                        position: "absolute",
                        top: "0.65rem",
                        right: "1rem",
                      }}
                    />
                  ) : (
                    <></>
                  )} */}
								</div>
								{password.length ? (
									<Button
										variant="outline-light"
										style={{ color: "#163172" }}
										onClick={togglePassword}
									>
										{passShow === false ? (
											<BsFillEyeFill />
										) : (
											<BsFillEyeSlashFill />
										)}
									</Button>
								) : (
									<></>
								)}
							</div>
							<div style={{ position: "relative" }}>
								<Form.Control
									className="mt-3"
									type={passShow ? "text" : "password"}
									placeholder="Confirm password"
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
								/>

								{passState === true ? (
									<BsFillCheckCircleFill
										style={{
											position: "absolute",
											top: "0.65rem",
											right: "1rem",
										}}
									/>
								) : (
									<></>
								)}
							</div>

							{/* <Form.Control
                className="mt-3"
                type="password"
                placeholder="Confirm password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> */}
							{errMsg.length > 0 ? (
								<Form.Text
									// className="text-muted"
									style={{
										color: "#ff0000",
										fontSize: "10px",
									}}
								>
									{errMsg}
								</Form.Text>
							) : (
								<></>
							)}
						</Form.Group>
						<br />
						<Form.Check
							style={{ textAlign: "center" }}
							type="checkbox"
							label="I allow Helix to use the anonymized data for research."
							defaultChecked={consent}
							onClick={() =>
								consent === true
									? setConsent(false)
									: setConsent(true)
							}
						/>{" "}
						<br />
						<Button
							className="mb-3"
							variant="light"
							style={{ color: "#163172", width: "100%" }}
							type="submit"
						>
							Register
						</Button>
					</Form>
					{/* <p style={{ color: "#163172", textAlign: "center" }}>or</p>
          <Button
            className="mb-3"
            variant="light"
            style={{ color: "#163172", width: "100%" }}
            type="submit"
            onClick={() => {
              // window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=token&scope=email+openid+profile&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/`;
              window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/&response_type=TOKEN&client_id=${process.env.REACT_APP_CLIENTID}&scope=email+openid+profile`;
            }}
          >
            <FcGoogle />
            &nbsp;&nbsp; Continue with Google
          </Button> */}
					<p className="text-center">
						Already have an account? <Link to="/login">Login</Link>
					</p>
				</Stack>
			</div>
		</div>
	);
};

export default Register;
