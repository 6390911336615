import React, { useContext, useState } from "react";
import { Stack, Button, Badge, Table } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import Navigate from "../../Navigate";
import { FaFolderMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const QuestionBank = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { questionBank, getQuestionBankList } = useContext(AssmtContext);

  let navigate = useNavigate();

  const updateQBank = async (QID, setInQBank) => {
    await apiCall(`admin/AddRemoveFrmQBank/${QID}`, "PUT", {
		inQBank: setInQBank,
	}).then(() => {
			getQuestionBankList();
		});
  };

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6><a href="#" onClick={() => navigate("/admin")}>&lt;Back</a></h6>
          <div>Question Bank</div>
          <br />
          {questionBank.length > 0 ? (
            <Stack gap={3}>
              {questionBank?.map((singleQB, qidx) => (
                <div key={qidx} className="view-qb-body">
                  <div className="d-flex justify-content-between">
                    <label>
                      {qidx + 1}.&nbsp;&nbsp;{singleQB.question}
                    </label>
                    <Button
                      size="sm"
                      onClick={() => {
                        updateQBank(singleQB.QID, 0);
                      }}
                    >
                      <FaFolderMinus />
                      &nbsp;&nbsp; QBank
                    </Button>
                  </div>
                  <Badge bg="secondary">Type: {singleQB.type}</Badge>
                  <Table bordered>
                    <tbody>
                      <tr>
                        <td>#</td>
                        <td>Choices</td>
                        <td>Weightage</td>
                      </tr>
                      {singleQB.choices.map((singleCho, cidx) => (
                        <tr key={cidx}>
                          <td>{cidx + 1}</td>
                          <td className="choice-col">{singleCho.choice}</td>
                          <td style={{ width: "15%" }}>
                            {singleCho.weightage}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ))}
            </Stack>
          ) : (
            <p>The List is empty!</p>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
    </div>
  );
};

export default QuestionBank;
