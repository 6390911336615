import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Stack, Card, Button, Form, Collapse, Accordion } from "react-bootstrap";
import { Steps } from "rsuite";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import Navigate from "../../Navigate";
import axios from "axios";
import DataTable from "react-data-table-component";
import { getBaseUrl } from "../../../base";
import ViewMultipleResult from "./ViewMultipleResult";
import ViewInventoryResult from "./ViewInventoryResult";
import ViewLikertResult from "./ViewLikertResult";
import ViewPickOneResult from "./ViewPickOneResult";
import ViewLikert6Result from "./ViewLikert6Result";
import moment from "moment";
import "moment-timezone";
import { useMediaQuery } from "react-responsive";
import arrowDown from "../../../image/Arrow-Down-icon.png";
import arrowUp from "../../../image/Arrow-Up-icon.png";


const ViewSingleResult = () => {
    const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

	const navigate = useNavigate();

	const [assmtObj, setAssmtObj] = useState({});
	const [sectionAnalysisData, setSectionAnalysisData] = useState([]);
	const [secObj, setSecObj] = useState([]);
	const [secStep, setSecStep] = useState(0);
	const [singleSecObj, setSingleSecObj] = useState({});
	const [allQinSec, setAllQinSec] = useState([]);
	const [allResponse, setAllResponse] = useState([]);
	const [userAssmtObj, setUserAssmtObj] = useState([]);
	const questionRef = useRef(null);
	const AGID = useLocation().state?.AGID;
	let secAssmtType = localStorage.getItem("secAssmtType");
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });
	const [showDetails, setShowDetails] = useState(false);

	const AID = useParams().AID;
	const UID = useParams().UID;
  	const UAID = useParams().UAID;

	  useEffect(() => {
		const getUserAssmtData = async () => {
			const assmtData = await apiCall(`admin/getResponseRefresh/${AID}/${UAID}`);
			await assmtData["data"].forEach((obj) => {
				obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
				obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
				obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
			});
		  	setUserAssmtObj(assmtData["data"][0]);
		};
		const getUserGrpAssmtData = async () => {
			const assmtData = await apiCall(`admin/getResponseRefresh/${AGID}/${UAID}`);
			await assmtData["data"].forEach((obj) => {
				obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
				obj.start_Tstamp = moment.utc(obj.start_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
				obj.end_Tstamp = moment.utc(obj.end_Tstamp).tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A');
			});
			setUserAssmtObj(assmtData["data"][0]);
			localStorage.setItem("AGID", AGID);
		};
		  
		if (userAssmtObj.length === 0 && (AGID === "undefined" || secAssmtType === "individual")) getUserAssmtData();
		else if (userAssmtObj.length === 0 && AGID && secAssmtType === "group") getUserGrpAssmtData();;
	  }, [UAID, loggedInUser.jwtToken]);

	useEffect(() => {
		if (!UAID || UAID === "") return;
		const getAllResponse = async (UAID) => {
			const { data } = await apiCall(`admin/getResponseByUser/${UAID}`);
			setAllResponse(data);
		};
		getAllResponse(UAID);
	}, [UAID, loggedInUser.jwtToken]);

	useEffect(() => {
		if (!AID || AID === "") return;

		const getAssmt = async (AID) => {
			const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
			setAssmtObj(data[0]);
		};
		getAssmt(AID);

		const getSections = async (AID) => {
			const { data } = await apiCall(`admin/getSections/${AID}`);
			setSecObj(data);
		};
		getSections(AID);

	}, [AID, loggedInUser.jwtToken]);

	const onChange = (nextStep) => {
		setSecStep(nextStep > secObj.length ? secObj.length : nextStep);
	};

	const onNext = () => onChange(secStep + 1);
	const onPrevious = () => onChange(secStep - 1);
	
	const toggleDetails = () => {
		setShowDetails(!showDetails);
	};

	useEffect(() => {
		if (!secObj || secObj.length === 0) return;
		setSingleSecObj({
			SecID: secObj[secStep].SecID,
			sec_name: secObj[secStep].sec_name,
			sec_desc: secObj[secStep].sec_desc,
		});
	}, [secObj, secStep]);

	useEffect(() => {
		if (
			!assmtObj.AID ||
			assmtObj.AID === "" ||
			!singleSecObj.SecID ||
			singleSecObj.SecID === ""
		) return;
		const getAllQuestionBySecID = async (AID, SecID) => {
			let { data } = await apiCall(`admin/getAllQuestions/${AID}/${SecID}`);
			setAllQinSec(data);
		};
		getAllQuestionBySecID(assmtObj.AID, singleSecObj.SecID);
	}, [assmtObj, singleSecObj, loggedInUser.jwtToken]);

	useEffect(() => {
		if (!UAID || UAID === "") return;
		const getSectionAnalysisData = async (AID, UAID, secObj) => {
			const { data } = await apiCall(`analysis/secscore/${AID}/${UAID}`, "POST", {responses: secObj});
			const formattedData = data.map(item => ({
				...item,
				sec_score: Math.floor(item.sec_score) === item.sec_score ? `${item.sec_score}.0` : Number(item.sec_score).toFixed(1),
			  }));
			const uniqueSecName = [...new Set(secObj.map((e) => e.sec_name))];
			const namedSec = formattedData.map((e,i) => {
				return {
				sec_no: uniqueSecName[i],
				sec_score: e.sec_score,
				};
			});								
			const { data: communicationScore } = await apiCall(`analysis/communicationScore/${AID}/${userAssmtObj.UID}`);
			if (communicationScore[UAID]) {
				setSectionAnalysisData([
				...namedSec,
				{
					sec_no: "Communication",
					sec_score: Number.isInteger(communicationScore)? `${communicationScore}.0` : Number(communicationScore[UAID]).toFixed(1),
				},
				]);
			} else setSectionAnalysisData(namedSec);
		};
		if(secObj.length !== 0) getSectionAnalysisData(AID, UAID, secObj);
 	}, [secObj, loggedInUser.jwtToken]);

	useEffect(() => {
		if (questionRef.current) {
			questionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [allQinSec]);

	const columns = [
		{
		  name: "Section Name",
		  selector: row => row["sec_no"],
		  sortable: true,
		  wrap: true,
		  minWidth: '13em'
		},
		{
		  name: "Score",
		  selector: row => row["sec_score"],
		  sortable: true
		}
		// {
		//   cell:(row) => <Button
		//   variant="outline-primary"
		//   onClick={onPrevious}
		//   disabled={secStep === 0}
		//   >
		//     Analyze
		//   </Button>,
		//   ignoreRowClick: true,
		//   allowOverflow: true,
		//   button: true,
		// }
	  ];
	  
	  const conditionalRowStyles = [
		{
		  when: row => row.sec_no === (secStep+1),
		  style: {
			backgroundColor: "#f0f7fc",
			userSelect: "none"
		  }
		}
	  ];

	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
					<h6><a href="#" onClick={() => navigate("/user/results/"+loggedInUser.UID)}>&lt;Back</a></h6>
					<div className="response">
						<div style={{width: isMobile? "90%":"25%"}} id="vctehc">
							{isMobile? (
								<Stack gap={2}>
									<Stack direction="horizontal" style={{width: "100%"}}>
										<Steps className="steps-bar" current={secStep}>
											{secObj.map((e) => {
												return <Steps.Item key={e.SecID} />;
											})}
										</Steps>
									</Stack>
									<img 
										src={showDetails ? arrowUp : arrowDown} 
										alt={showDetails ? "arrow up" : "arrow down"} 
										style={{width: "20px", height: "20px", marginBottom: "10px", alignSelf: "center"}}  
										onClick={toggleDetails}
									/>
									<Collapse in={showDetails}>
										<Stack gap={2} style={{ width: "100%", marginBottom: "20px" }}>
											<Card>
												<Card.Body>
													<Card.Title>
														{loggedInUser.first_name}&nbsp;
														{loggedInUser.last_name}
													</Card.Title>
													<label className="text-muted">
														Position:{" "}
														{userAssmtObj.positionOption}&nbsp;
													</label>
													<label className="text-muted">
														Title: {userAssmtObj.positionTitle}
													</label>
													{/* <label className="text-muted">
														Reference date:{" "}
														{new Date(
															userAssmtObj.referenceDate
														).toLocaleDateString()}
													</label> */}
													<label className="text-muted">
														Start time:{" "}
														{new Date(
															userAssmtObj.start_Tstamp
														).toLocaleString("en-US", {
															timeZone: "America/New_York",
														})}
													</label>
													<label className="text-muted">
														End time:{" "}
														{new Date(
															userAssmtObj.end_Tstamp
														).toLocaleString("en-US", {
															timeZone: "America/New_York",
														})}
													</label>
												</Card.Body>
											</Card>
											<Card>
												<Card.Body>
													<label className="mb-2">
														Assessment: "{assmtObj.name}"
													</label>
													<br />
													<label className="text-muted">
														{assmtObj.description}
													</label>
													<br />
													{assmtObj.url && assmtObj.url!=="" ? (
														<>
														<label className="text-muted" id="cbsjcnh">
															To learn about your results please visit {" "}
															{assmtObj.url.split(',').map((url, index, array) => (
															<React.Fragment key={index}>
																<a 
																href={url.trim()} 
																target="_blank" 
																rel="noopener noreferrer"
																onClick={(e) => {
																	if (!window.confirm('You will be leaving helix.com. Are you sure?')) {
																	e.preventDefault();
																	}
																}}
																>
																{url.trim()}
																</a>
																{index !== array.length - 1 && ',   '}
															</React.Fragment>
															))}
														</label><br></br>
														</>
													):(
													null
													)}
												</Card.Body>
											</Card>
											<Card>
												<Card.Body>
													<label className="mb-2">
														Section: "{singleSecObj.sec_name}"
													</label>
													<br />
													<label className="text-muted">
														{singleSecObj.sec_desc}
													</label>
												</Card.Body>
											</Card>
											<Card>
												<Card.Body>
													<DataTable
													title={
														<div id="adbcdxhja" style={{ textAlign: 'left' }}>
														<div>Analysis</div>
														<div style={{
															color: '#68696B',
															textAlign: 'left',
															fontSize: '12px',
															lineHeight: '20px',
															letterSpacing: 'normal',
															opacity: 1,
															textAlign: 'left',
															marginTop: '5px',
															wordWrap: 'break-word',
															overflowWrap: 'break-word',
														}}>
															Reference Date: {new Date(userAssmtObj.referenceDate).toLocaleDateString()}
														</div>
														</div>
													}
													columns={columns}
													data={sectionAnalysisData}
													defaultSortField="Section Name"
													conditionalRowStyles={conditionalRowStyles}
													pagination={false}
													paginationComponentOptions={
														{noRowsPerPage: false}
													}
													/>
													<br></br>
													<Button
														variant="outline-primary"
														onClick={() => {
															if(secAssmtType === "individual") navigate(`/analyze/bysection/${AID}/${UID}`, {state: {userAssmtObj, UAID}});
															else navigate(`/analyze/bysection/${AGID}/${UID}`, {state: {userAssmtObj, UAID}});
														}}
														disabled={sectionAnalysisData.length === 0}
														style={{width: "-webkit-fill-available"}}
														>
															Analyze
													</Button>
												</Card.Body>
											</Card>
										</Stack>
									</Collapse>
								</Stack>
							):(
							<Stack gap={2}>
								<Card>
									<Card.Body>
										<Card.Title>
											{loggedInUser.first_name}&nbsp;
											{loggedInUser.last_name}
										</Card.Title>
										<label className="text-muted">
											Position:{" "}
											{userAssmtObj.positionOption}&nbsp;
										</label>
										<label className="text-muted">
											Title: {userAssmtObj.positionTitle}
										</label>
										{/* <label className="text-muted">
											Reference date:{" "}
											{new Date(
												userAssmtObj.referenceDate
											).toLocaleDateString()}
										</label> */}
										<label className="text-muted">
											Start time:{" "}
											{new Date(
												userAssmtObj.start_Tstamp
											).toLocaleString("en-US", {
												timeZone: "America/New_York",
											})}
										</label>
										<label className="text-muted">
											End time:{" "}
											{new Date(
												userAssmtObj.end_Tstamp
											).toLocaleString("en-US", {
												timeZone: "America/New_York",
											})}
										</label>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body>
										<label className="mb-2">
											Assessment: "{assmtObj.name}"
										</label>
										<label className="text-muted">
											{assmtObj.description}
										</label>
										{assmtObj.url && assmtObj.url!=="" ? (
											<>
											<label className="mb-2 text-muted" id="cbsjcnh">
												To learn about your results please visit {" "}
												{assmtObj.url.split(',').map((url, index, array) => (
												<React.Fragment key={index}>
													<a 
													href={url.trim()} 
													target="_blank" 
													rel="noopener noreferrer"
													onClick={(e) => {
														if (!window.confirm('You will be leaving helix.com. Are you sure?')) {
														e.preventDefault();
														}
													}}
													>
													{url.trim()}
													</a>
													{index !== array.length - 1 && ',   '}
												</React.Fragment>
												))}
											</label><br></br>
											</>
										):(
										null
										)}
									</Card.Body>
								</Card>
								<Card>
									<Card.Body>
										<label className="mb-2">
											Section: "{singleSecObj.sec_name}"
										</label>
										<br />
										<label className="text-muted">
											{singleSecObj.sec_desc}
										</label>
									</Card.Body>
								</Card>
								<Card>
									<Card.Body>
										<DataTable
										title={
											<div id="adbcdxhja" style={{ textAlign: 'left' }}>
											  <div>Analysis</div>
											  <div style={{
												color: '#68696B',
												textAlign: 'left',
												fontSize: '12px',
												lineHeight: '20px',
												letterSpacing: 'normal',
												opacity: 1,
												textAlign: 'left',
												marginTop: '5px',
												wordWrap: 'break-word',
												overflowWrap: 'break-word',
											  }}>
												Reference Date: {new Date(userAssmtObj.referenceDate).toLocaleDateString()}
											  </div>
											</div>
										  }
										columns={columns}
										data={sectionAnalysisData}
										defaultSortField="Section Name"
										conditionalRowStyles={conditionalRowStyles}
										pagination={false}
										paginationComponentOptions={
											{noRowsPerPage: false}
										}
										/>
										<br></br>
										<Button
											variant="outline-primary"
											onClick={() => {
												if(secAssmtType === "individual") navigate(`/analyze/bysection/${AID}/${UID}`, {state: {userAssmtObj, UAID}});
												else navigate(`/analyze/bysection/${AGID}/${UID}`, {state: {userAssmtObj, UAID}});
											}}
											disabled={sectionAnalysisData.length === 0}
											>
												Analyze
										</Button>
									</Card.Body>
								</Card>
							</Stack>
							)}
						</div>
						<div className="viewResponse-body">
							{isMobile? (
								<></>
							):(
							<>	
								<Stack direction="horizontal" style={{position: "fixed", width: "62.7%"}}>
									<Button
										variant="outline-primary"
										onClick={onPrevious}
										disabled={secStep === 0}
									>
										Prev
									</Button>
									<Steps className="steps-bar" current={secStep}>
										{secObj.map((e) => {
											return <Steps.Item key={e.SecID} />;
										})}
									</Steps>
									<Button
										variant="outline-primary"
										onClick={onNext}
										disabled={secStep >= secObj.length - 1}
									>
										Next
									</Button>
								</Stack>
								<br />
							</>	
							)}
							{isMobile? (
							<>
								<Accordion>
									{allQinSec.map((q, qqid) => (
										<Card key={qqid}>
											<Accordion.Item eventKey={qqid.toString()}>
												<Accordion.Header><span className="truncate-text">{q.question}</span></Accordion.Header>
												<Accordion.Body>
													<Form.Label style={{marginBottom: '1rem'}}>{q.question}</Form.Label>
													<div>
														{q.type === "Multiple" ? (
															<ViewMultipleResult
																q={q}
																UAID={UAID}
																allResponse={allResponse}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type === "Inventory" ? (
															<ViewInventoryResult
																q={q}
																UAID={UAID}
																allResponse={allResponse}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type === "Likert" ? (
															<ViewLikertResult
																q={q}
																UAID={UAID}
																allResponse={allResponse}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type === "Likert-6" ? (
															<ViewLikert6Result
																q={q}
																UAID={UAID}
																allResponse={allResponse}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type === "Pick One" ? (
															<ViewPickOneResult
																q={q}
																UAID={UAID}
																allResponse={allResponse}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : (
															<></>
														)}
													</div>
												</Accordion.Body>
											</Accordion.Item>
										</Card>
									))}
								</Accordion>
								<Stack direction="vertical" gap={3} style={{marginTop: "1rem"}}>
									<Button
										variant="outline-primary"
										onClick={onPrevious}
										disabled={secStep === 0}
										style={{width: "-webkit-fill-available"}}
									>
										Prev
									</Button>
									<Button
										variant="outline-primary"
										onClick={onNext}
										disabled={secStep >= secObj.length - 1}
										style={{width: "-webkit-fill-available"}}
									>
										Next
									</Button>
								</Stack>
							</>
							):(
								<Stack className="question" style={{marginTop: "2.35rem"}}>
									<ol ref={questionRef}>
										{allQinSec.map((q, qqid) => (
											<li key={qqid}>
												<div
													className="u-question-body"
													key={q.QID}
												>
													<Form.Label>
														{q.question}
													</Form.Label>
													<div>
														{q.type === "Multiple" ? (
															<ViewMultipleResult
																q={q}
																UAID={UAID}
																allResponse={
																	allResponse
																}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type ===
														"Inventory" ? (
															<ViewInventoryResult
																q={q}
																UAID={UAID}
																allResponse={
																	allResponse
																}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type === "Likert" ? (
															<ViewLikertResult
																q={q}
																UAID={UAID}
																allResponse={
																	allResponse
																}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type ===
														"Likert-6" ? (
															<ViewLikert6Result
																q={q}
																UAID={UAID}
																allResponse={
																	allResponse
																}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : q.type ===
														"Pick One" ? (
															<ViewPickOneResult
																q={q}
																UAID={UAID}
																allResponse={
																	allResponse
																}
																isMobile={isMobile}
																isLandscapeMobile={isLandscapeMobile}
															/>
														) : (
															<></>
														)}
													</div>
												</div>
												<br />
											</li>
										))}
									</ol>
								</Stack>
							)}
						</div>
					</div>
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default ViewSingleResult;
