import React, { useContext, useState, useEffect } from "react";
import $ from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Form,
  Card,
  Button,
  Accordion,
  Badge,
  Table,
  Modal,
} from "react-bootstrap";
import { AssmtContext } from "../../Contexts/AssmtContext";
import Navigate from "../../Navigate";
import NFCListSet from "../Assmt/NFCList.js";
import ToastMessage from "../../ToastMessage";
import { FaEdit, FaEye } from "react-icons/fa";
import { AiOutlineRadarChart, AiOutlineCloseCircle, AiTwotoneDelete } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { RiSaveFill } from "react-icons/ri";
import {
  TbFileSymlink,
  TbFileCheck,
  TbFileX,
  TbFiles,
  TbFileDownload,
  TbFileAnalytics,
  TbFileSettings,
  TbCheckbox
} from "react-icons/tb";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { CSVLink } from "react-csv";
import { FaFolderMinus, FaFolderPlus } from "react-icons/fa";
import PreviewGroupAssmt from "./PreviewGroupAssmt";
import {default as RModal} from "react-modal/lib/components/Modal";
import ViewAssmt from "../ViewAssmt/ViewAssmt";
import ViewEachAssmt from "./ViewEachAssmt";
import moment from "moment";
import 'moment-timezone';

RModal.setAppElement("#root");

const ViewGroupAssmt = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus, setAssmt } = useContext(AssmtContext);

  const navigate = useNavigate();

  const AID = useParams().id;

  const [assmtObj, setAssmtObj] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [assmtGroup, setAssmtGroup] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [analysisBtnDisabled, setAnalysisBtnDisabled] = useState(false);
  const [editNFCRange, setEditNFCRange] = useState(true);
  const [prevBool, setPrevBool] = useState(false);
  const [nfcEditing, setNfcEditing] = useState(false);
  const [nfcCheckedList, setNfcCheckedList] = useState([]);
  const [hcsCheckedList, setHcsCheckedList] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [allUserRes, setAllUserRes] = useState([]);
  const [isOpenNFCModal, setIsOpenNFCModal] = useState(false);

  const [deleteOpr, setDeleteOpr] = useState("");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const toggleBtn = () => {
    setBtnDisabled(!btnDisabled);
  };

  const toggleprev = () => {
    setPrevBool(!prevBool);
  };

  const toggleAnalysisBtn = () => {
    setAnalysisBtnDisabled(!analysisBtnDisabled);
  };

  const editNFCRangefunc = () => {
    setEditNFCRange(!editNFCRange);
  }

  const getAssmtdetails = async (AID) => {
		const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
		
		const cnt = await apiCall(`admin/getResponseCount/${AID}`);
		
		data[0]["hasResponse"] = cnt.data > 0 ? true : false;
		setAssmtObj(data[0]);
  };

  const exportAllUserRes = async (id) => {
		const { data } = await apiCall(`admin/exportAllAssmtResToCSV/${id}`);
		setAllUserRes(data);
  };

  useEffect(() => {
		if (!AID || AID === "" || !loggedInUser.jwtToken) return;
		getAssmtdetails(AID);
  }, [AID, loggedInUser.jwtToken]);

  useEffect(() => {
    async function getData() {
      const { data } = await apiCall(`admin/getGrpAssmtByID/${AID}`);
      setAssmtGroup(data);
    }
    if (!AID || AID === "" || !loggedInUser.jwtToken) return;
    else getData();
  }, [AID, loggedInUser.jwtToken]);

  const handleOnClickView = (id) => {
    let assmtName = {assmtName: assmtObj.name};
    localStorage.setItem('viewResAssmtName', JSON.stringify(assmtName));
		navigate(`/admin/viewAllResponse/${id}`);
  };

  const handleOnClickPublish = async (id) => {
    let flag = true;
    for(let i = 0; i<sectionList.length; i++) {
      if(sectionList[i].nfc === "1" && sectionList[i].nfcData.length === 0) {
        flag = false;
        setMessage(`NFC checked but range not provided in Section #${i+1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if(sectionList[i].sType === "") {
        flag = false;
        setMessage(`Analysis type not selected for Section #${i+1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if(sectionList[i].qIDs.length === 0) {
        flag = false;
        setMessage(`No question found in Section #${i+1}, to publish add atleast one question and choice`);
        setStatus(false);
        setToast(true);
      } else {
        let questions = sectionList[i].qIDs;
        for(let j = 0; j<questions.length; j++) {
          if(questions[j].cIDs.length === 0) {
            flag = false;
            setMessage(`No choices found in Section #${i+1}, question #${j+1}, to publish add atleast one choice and it's weightage`);
            setStatus(false);
            setToast(true);
          }
        }
      }
    }
    if(flag) {
      let publish = await apiCall(`admin/publishAssmt/${id}`, "PUT");
      if (publish) {
        const { data } = await apiCall(`admin/`);
        setAssmt(data);
        setMessage("Assessment published!");
        setStatus(true);
        setToast(true);

        navigate("/admin");
      }
    }
  };

  const handleOnClickUnpublish = async (id) => {
    let unpublish = await apiCall(`admin/unpublishAssmt/${id}`, "PUT");
    if (unpublish) {
      const { data } = await apiCall(`admin/`);
      setAssmt(data);
      setMessage("Assessment unpublished!");
      setStatus(true);
      setToast(true);

      navigate("/admin");
    }
  };

  const handleOnClickDelete = async (id) => {
    let deleteAssmt = apiCall(`admin/deleteAssmt/${id}`, "DELETE");
    if (deleteAssmt) {
      const { data } = await apiCall("admin/");
      setMessage("Assessment deleted!");
      setStatus(true);
      setToast(true);
      setAssmt(data);
      navigate("/admin", { replace: false, state: { reLoad: true } });
    }
  };

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <h6><a href="#" onClick={() => window.history.back()}>&lt;Back</a></h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtObj.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to permanently delete the assessment ?{" "}
              <br />
              The questions added in the Question Bank will also be removed.
              <br />
              <br />
              <p>
                you must enter "<span style={{ color: "red" }}>delete</span>" to
                proceed.
              </p>
              <input
                onChange={(e) => {
                  setDeleteOpr(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button
                disabled={deleteOpr === "delete" ? false : true}
                variant="danger"
                onClick={() => handleOnClickDelete(AID)}
              >
                Delete
              </Button>
              
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Helix</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {`All user response for ${assmtObj.name} is generated as .csv file and ready to dowload!`}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Cancel
              </Button>
              <CSVLink
                data={allUserRes}
                headers={[
                  { label: "User ID", key: "UID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={`${
                  assmtObj.name
                } (exported on ${new Date().toLocaleDateString()})`}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose2}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <div className="newAssmt">
            <div className="w-25 fixed">
              <Card>
                <Card.Body>
                  <Card.Title>{assmtObj.name}</Card.Title>
                  {/* <label className="mb-2 text-muted">
                    {assmtObj.description}
                  </label> */}
                  <label className="mb-2 text-muted">
                    Created on:{" "}
                    {moment.utc(assmtObj.TOC).tz("America/New_York").format("MM/DD/YYYY hh:mm:ss A")}
                  </label>
                  <label className="mb-2 text-muted">
                    Published: {assmtObj.published}
                  </label>
                  {assmtObj.published === "Yes" ? (
                    <label className="mb-2 text-muted">
                      Published on:{" "}
                      {moment.utc(assmtObj.TOP).tz("America/New_York").format("MM/DD/YYYY hh:mm:ss A")}
                    </label>
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>

              <Form.Group className="mb-3 mt-4">
                <Button
                  variant="light"
                  style={{ color: "#163172" }}
                  className="createAssmt-btn mb-3"
                  onClick={() => handleOnClickView(AID)}
                >
                  <FaEye style={{ color: "#163172", fontSize: "20px" }} />
                  &nbsp;&nbsp; View response
                </Button>

                <Button
                variant="light"
                style={{ color: "#163172" }}
                className="createAssmt-btn mb-3"
                // disabled={analysisBtnDisabled || btnDisabled || nfcEditing || hcsEditing}
                onClick={() => {
                  navigate(`/admin/editGrpAssmt/${AID}`);
                }}
                >
                  <FaEdit
                    style={{ color: "#163172", fontSize: "20px" }}
                  />
                  &nbsp;&nbsp; Edit
                </Button>
                
                {assmtObj.published === "Yes" ? (
                  <>
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn mb-3"
                      // disabled={analysisBtnDisabled || btnDisabled || prevBool || nfcEditing || hcsEditing}
                      onClick={() => handleOnClickUnpublish(AID)}
                    >
                      <TbFileX style={{ color: "#163172", fontSize: "20px" }} />
                      &nbsp;&nbsp; Unpublish
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn mb-3"
                      // disabled={analysisBtnDisabled || btnDisabled || prevBool || nfcEditing || hcsEditing}
                      onClick={() => handleOnClickPublish(AID)}
                    >
                      <TbFileCheck
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Publish
                    </Button>
                  </>
                )}

                <Button
                  variant="light"
                  style={{ color: "#163172" }}
                  className="createAssmt-btn mb-3"
                  // disabled={analysisBtnDisabled || btnDisabled || nfcEditing || hcsEditing}
                  onClick={() => toggleprev()}
                >
                  {prevBool === false ? (
                    <>
                      <TbFileSymlink
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Preview
                    </>
                  ) : (
                    <>
                      <MdArrowBack
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Back
                    </>
                  )}
                </Button>

                <Button
                  variant="light"
                  style={{ color: "#163172" }}
                  className="createAssmt-btn mb-3"
                  // disabled={analysisBtnDisabled || btnDisabled || prevBool || nfcEditing || hcsEditing}
                  onClick={handleShow1}
                >
                  <AiTwotoneDelete
                    style={{ color: "#BD4B4B", fontSize: "20px" }}
                  />
                  &nbsp;&nbsp; Delete
                </Button>

              </Form.Group>
            </div>
            <div className="assmt-b">
              {prevBool === false ? (
                <>
                  {assmtGroup.map((singleAssmt, qidx) => (
                    <div key={qidx}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey={qidx}>
                        <Accordion.Header>
                          {singleAssmt["name"]}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ViewEachAssmt
                            preview={false}
                            AID={singleAssmt.AID}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                    </div>
                  ))}
                </>
              ) : (
                // preview code to be added here
                <>
                  {assmtGroup.map((singleAssmt, qidx) => (
                    <div key={qidx}>
                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey={"0"}>
                          <Accordion.Header>
                            {singleAssmt["name"]}
                          </Accordion.Header>
                          <Accordion.Body>
                            <ViewEachAssmt
                              preview={true}
                              AID={singleAssmt.AID}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <br />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ViewGroupAssmt;
