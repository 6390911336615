// ViewAllResponse.jsx
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Navigate from "../../Navigate";
import UserResponseTable from "./UserResponseTable";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { Button, Modal, FormLabel } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AssmtContext } from "../../Contexts/AssmtContext";
import moment from "moment";
import "moment-timezone";
import { Helmet } from "react-helmet";
import ToastMessage from "../../ToastMessage";

const ViewAllResponse = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const {
    assmtType,
    setAssmtType,
    toast,
    setToast,
    message,
    setMessage,
    status,
    setStatus,
  } = useContext(AssmtContext);

  const navigate = useNavigate();
  const location = useLocation(); // Import useLocation

  const param = useParams();
  const AID = param.id;

  // Retrieve 'page' from location.state if available
  const initialPage = location.state?.page || 0;

  let assmtData = JSON.parse(localStorage.getItem("viewResAssmtName"));
  let assmtName = assmtData ? assmtData["assmtName"] : "";

  const [userAssmtDetails, setUserAssmtDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [csvFilename, setCSVfilename] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [userRes, setUserRes] = useState([]);
  const [bodyName, setBodyName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [responsesToDelete, setResponsesToDelete] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getResponse = async () => {
      let response;
      if (assmtType) {
        if (assmtType === "individual") {
          response = await apiCall(`admin/getResponse/${AID}`);
        } else if (assmtType === "group") {
          response = await apiCall(`admin/getGrpAssmtResponse/${AID}`);
        }
      } else {
        const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
        let type = data[0]["type"];
        setAssmtType(data[0]["type"]);
        if (type === "individual") {
          response = await apiCall(`admin/getResponse/${AID}`);
        } else if (type === "group") {
          response = await apiCall(`admin/getGrpAssmtResponse/${AID}`);
        }
      }
      let data = response["data"];
      data.forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment
          .utc(obj.start_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
        obj.end_Tstamp = moment
          .utc(obj.end_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
      });
      setUserAssmtDetails(data);
    };
    if (loggedInUser.jwtToken) getResponse();

    const getAssmtDetails = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      assmtName = data[0]["name"];
    };
    if (!assmtName) getAssmtDetails(AID);
  }, [loggedInUser.jwtToken, assmtType, AID, apiCall]);

  // Functions for delete modal
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // Single response delete function
  const deleteCurrentResponse = async (UAID) => {
    try {
      let deleteUserRes = await apiCall(
        `admin/deleteUserRes/${UAID}`,
        "DELETE"
      );
      console.log("Log : ", deleteUserRes);

      // if error from the server side
      if (deleteUserRes.response?.data?.error) {
        setMessage(deleteUserRes.response.data.error);
        setToast(true);
        return;
      }

      // if no error then filter it out
      setUserAssmtDetails((assessments) => {
        return assessments.filter((assessment) => {
          return assessment.UAID !== UAID;
        });
      });
      setMessage("User response has been deleted successfully!");
      setStatus(true);
      setToast(true);
    } catch (err) {
      console.log("ERROR : ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      return;
    }
  };

  // Bulk response delete function
  const deleteBulkResponses = async (UAIDs) => {
    try {
      let deleteUserRes = await apiCall(`admin/deleteBulkResponses`, "POST", {
        UAIDs,
      });
      // console.log("Log : ",deleteUserRes);

      // if error from the server side
      if (deleteUserRes.response?.data?.error) {
        setMessage(deleteUserRes.response.data.error);
        setToast(true);
        return;
      }

      // if no error then filter it out
      setUserAssmtDetails((assessments) => {
        return assessments.filter((assessment) => {
          return !UAIDs.includes(assessment.UAID);
        });
      });
      setResponsesToDelete([]);
      setMessage("User responses have been deleted successfully!");
      setStatus(true);
      setToast(true);
    } catch (err) {
      console.log("ERROR : ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      return;
    }
  };

  // Functions for updating response list for deletion
  const addRecordToDeletionList = (UAID) => {
    setResponsesToDelete((ids) => {
      return [...ids, UAID];
    });
  };

  const removeRecordFromDeletionList = (UAID) => {
    setResponsesToDelete((responses) => {
      return responses.filter((id) => {
        return id !== UAID;
      });
    });
  };

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Helmet>
            <title>
              Helix Employability Tool | View All Responses - {assmtName}
            </title>
          </Helmet>
          <Navigate />
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          {/* Back Button to '/admin/viewAssmt/' + AID */}
          <h6>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate("/admin/viewAssmt/" + AID);
              }}
            >
              &lt;Back
            </a>
          </h6>
          {/* Modals for CSV and Deletion */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyName}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <CSVLink
                data={userRes}
                headers={[
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={csvFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>

          {/* Delete Modal  */}
          <Modal
            show={showDeleteModal}
            onHide={handleCloseDeleteModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {responsesToDelete.length === 0
                ? `Do you want to delete ${assessmentDetails.first_name} ${assessmentDetails.last_name}'s response for this said assessment? This data will not be recovered!`
                : `Do you want to delete all the selected responses for the said assessment? This data will not be recovered!`}
              <br />
              <br />
              <p>
                You must enter "<span style={{ color: "red" }}>delete</span>" to
                proceed.
              </p>
              <input
                onChange={(e) => {
                  setDeleteInput(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDeleteModal}>
                Close
              </Button>
              <Button
                disabled={deleteInput.trim().toLowerCase() !== "delete"}
                variant="danger"
                onClick={() => {
                  responsesToDelete.length === 0
                    ? deleteCurrentResponse(assessmentDetails.UAID)
                    : deleteBulkResponses(responsesToDelete);
                  handleCloseDeleteModal();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex justify-content-between m-3">
            <FormLabel>Response list for Assessment "{assmtName}"</FormLabel>
            {responsesToDelete.length !== 0 && (
              <Button
                variant="outline-danger"
                onClick={() => handleShowDeleteModal()}
              >
                Delete Responses
              </Button>
            )}
          </div>

          <UserResponseTable
            assmtID={AID}
            userAssmtDetails={userAssmtDetails}
            setCSVfilename={setCSVfilename}
            setHeaderName={setHeaderName}
            setUserRes={setUserRes}
            handleShow={handleShow}
            setBodyName={setBodyName}
            handleShowDeleteModal={handleShowDeleteModal}
            setAssessmentDetails={setAssessmentDetails}
            addRecordToDeletionList={addRecordToDeletionList}
            removeRecordFromDeletionList={removeRecordFromDeletionList}
            responsesToDelete={responsesToDelete}
            initialPage={initialPage} // Pass initialPage as a prop
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ViewAllResponse;
